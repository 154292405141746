import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { COLUMN_TYPES, ROLE_TO_KEY, STAGE_TO_KEY } from "./column-types";
import BooleanState from "../badge/boolean-badge";
import {
  CATEGORIES_CONFIG,
  SUB_CATEGORIES_CONFIG,
} from "../../pages/data/categories-settings";

const TdContent = ({ name, type, helperKey, row = {} }) => {
  const { t } = useTranslation();

  switch (type) {
    case COLUMN_TYPES.ROLE:
      return t(ROLE_TO_KEY[row[name]]) || row[name];

    case COLUMN_TYPES.STAGE:
      return t(STAGE_TO_KEY[row[name]]) || row[name];

    case COLUMN_TYPES.GROUP_CATEGORY:
      return t(CATEGORIES_CONFIG[row[name]]?.labelKey) || row[name];

    case COLUMN_TYPES.GROUP_SUB_CATEGORY:
      return t(SUB_CATEGORIES_CONFIG[row[name]]?.labelKey) || row[name];

    case COLUMN_TYPES.BOOLEAN:
      return <BooleanState value={row[name]} />;

    case COLUMN_TYPES.DOWNLOAD:
      return (
        <a
          aria-label={t("downLoadLbl")}
          className="mx-auto"
          rel="noreferrer"
          target="_blank"
          href={row.link}
        >
          <FaDownload />
        </a>
      );

    default:
      return (
        <>
          {Array.isArray(row[name]) ? row[name].join(", ") : row[name]}
          {helperKey && (
            <p className="mt-1 text-sm text-neutral-400 ">{row[helperKey]}</p>
          )}
        </>
      );
  }
};

TdContent.propTypes = {
  // row: PropTypes.shape(PropTypes.object),
  name: PropTypes.string,
  helperKey: PropTypes.string,
  type: PropTypes.string,
};

export default TdContent;
