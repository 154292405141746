import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";
import { USER_ROLES } from "../../components/groups/user-groups-settings";
import Table from "../../components/table/table";
import { FOLDERS_MOCK, FILES_TAGS_MOCK } from "./files-mock";
import { ENTITY_COLUMNS, MAKE_ACTIONS_LABELS } from "./data-columns";
import Pagination from "../../components/pagination";
import Tags from "../../components/tags";
import BreadCrumbs from "../../components/breadcrumbs";

import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import { useNavigate } from "react-router-dom";

const FILES_PER_PAGE = 10;

const HydroMeteorologyPage = ({ role }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTags, setActiveTags] = useState([]);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleTagClick = (tag) => {
    if (activeTags.includes(tag)) {
      setActiveTags(activeTags.filter((item) => item !== tag));
    } else {
      setActiveTags([...activeTags, tag]);
    }
  };

  const actions = Object.values(MAKE_ACTIONS_LABELS).map((item) => ({
    ...item,
    // todo: replace with real url
    onClick: () => {
      navigate("/data/hydrometeorology/1sakjshkywiqqe");
    },
  }));

  const showPagination = FOLDERS_MOCK.length > FILES_PER_PAGE;
  // todo: replace with request to API
  const data = useMemo(() => {
    if (activeTags.length === 0) {
      return FOLDERS_MOCK;
    }
    return FOLDERS_MOCK.slice(2, 4);
  }, [activeTags]);

  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  return (
    <LayoutWithSidebar>
      <div>
        <h1 className="text-gray-1100 font-bold text-lg md:text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
          {t("hydrometeorology")}
        </h1>

        <BreadCrumbs
          currentPage={t("hydrometeorology")}
          links={[{ name: t("dataTitle"), link: "/data" }]}
        />

        <div className="flex flex-col gap-8">
          <Tags
            tags={FILES_TAGS_MOCK}
            activeTags={activeTags}
            onClick={handleTagClick}
          />

          <div className="flex flex-col md:flex-row justify-between">
            <div className="min-w-[300px] ml-auto">
              <Datepicker
                value={value}
                onChange={(newValue) => setValue(newValue)}
                maxDate={new Date()}
              />
            </div>
          </div>

          <section
            className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
          >
            <Table
              columns={ENTITY_COLUMNS}
              rowActions={actions}
              data={data.slice(
                currentPage * FILES_PER_PAGE,
                (currentPage + 1) * FILES_PER_PAGE,
              )}
            />

            {showPagination && (
              <Pagination
                className="mt-[30px] mb-[21px]"
                total={FOLDERS_MOCK.length}
                onClick={handlePageChange}
                currentPage={currentPage}
                itemsPerPage={FILES_PER_PAGE}
              />
            )}
          </section>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};
HydroMeteorologyPage.propTypes = {
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
};

export default HydroMeteorologyPage;
