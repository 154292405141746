import React, { useState } from "react";
import Layout from "../../layout/layout";
import logo from "../../img/logo/logo.png";
import { useTranslation } from "react-i18next";
import Input from "../../components/form/input";
import { INPUT_ICONS } from "../../components/form/input-icon";
import InputPassword from "../../components/form/input-password";
import Submit from "../../components/form/submit";
import LinkText from "../../components/link/link-text";
import { getIsPasswordInValid, getIsEmailInValid } from "./constants";

const RegistrationPage = () => {
  const { t } = useTranslation();
  const [userRegistr, setUserRegistr] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const isEmailInValid = getIsEmailInValid(userRegistr.email);
  const isPasswordInValid = getIsPasswordInValid(
    userRegistr.password,
    userRegistr.confirmPassword,
  );

  const isFormValid =
    !isEmailInValid &&
    !isPasswordInValid &&
    Object.values(userRegistr).every((el) => el !== "");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserRegistr((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Данні форми:", userRegistr);
  };

  return (
    <Layout>
      <div>
        <form
          className="rounded-2xl bg-white mx-auto p-10 text-center max-w-[440px] my-[84px] dark:bg-[#1F2128]"
          action=""
          method=""
          onSubmit={handleSubmit}
        >
          <div className="mb-4 text-center mx-auto">
            <img
              src={logo}
              alt="logo"
              className="rounded-[50%] w-[60px] sm:w-[80px] lg:w-[80px] xl:w-[100px] mx-auto"
            />
          </div>
          <h1 className="font-bold text-2xl text-gray-1100 capitalize mb-[5px] dark:text-gray-dark-1100">
            {t("registrationTitle")}
          </h1>
          <p className="text-sm text-gray-500 mb-[30px] dark:text-gray-dark-500">
            {t("registrationSubtitle")}
          </p>

          <div className="flex flex-col gap-4 text-left">
            <Input
              label={t("firstName")}
              value={userRegistr.firstName}
              onChange={handleChange}
              id="firstName"
              required
              icon={INPUT_ICONS.USER}
            />
            <Input
              label={t("lastName")}
              value={userRegistr.lastName}
              onChange={handleChange}
              id="lastName"
              required
              icon={INPUT_ICONS.USER}
            />
            <Input
              type="email"
              label={t("email")}
              value={userRegistr.email}
              onChange={handleChange}
              id="email"
              icon={INPUT_ICONS.MAIL}
              required
            />

            <InputPassword
              label={t("password")}
              value={userRegistr.password}
              onChange={handleChange}
              id="password"
              required
            />

            <InputPassword
              label={t("confirmPassword")}
              value={userRegistr.confirmPassword}
              onChange={handleChange}
              id="confirmPassword"
              required
            />

            <div className="text-red text-sm">
              {isEmailInValid && <p>{t("emailNotValid")}</p>}
              {isPasswordInValid && <p>{t("passwordInvalidWithConfirm")}</p>}
            </div>

            <Submit label={t("registrationBtn")} disabled={!isFormValid} />
          </div>

          <p className="text-sm text-gray-500 mt-8">
            {t("alreadyHaveAccount")}{" "}
            <LinkText href="/login" isPlain={false}>
              {t("login")}
            </LinkText>
          </p>
        </form>
      </div>
    </Layout>
  );
};

export default RegistrationPage;
