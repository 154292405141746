import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
const LinkText = ({
  href,
  target,
  title,
  children,
  className,
  isPlain = true,
  ...rest
}) => {
  const navigate = useNavigate();
  const isExternal = target === "_blank";
  const onClick = (e) => {
    if (!isExternal) {
      e.preventDefault();
      navigate(href);
    }
  };

  return (
    <a
      href={href || "#"}
      title={title || ""}
      {...(isExternal ? { rel: "noreferrer" } : {})}
      className={`${className} ${isPlain ? "" : "text-color-brands"}`}
      target={target || "_self"}
      onClick={onClick}
      {...rest}
    >
      {children || title}
    </a>
  );
};
LinkText.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
};
export default LinkText;
