import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Settings from "../settings/settings";
import LinkText from "../link/link-text";

const Footer = ({ showSettings }) => {
  const { t } = useTranslation();

  return (
    <footer className="pb-[25px] font-light text-gray-900 dark:text-gray-300">
      <div className="w-full bg-neutral h-[1px] dark:bg-dark-neutral-border mb-[25px]"></div>
      <div
        className="flex flex-grow-0 items-center justify-between
       text-desc font-normal flex-wrap gap-5 px-[25px]"
      >
        <div className="flex items-center gap-2 flex-wrap">
          <p>
            <span>© {new Date().getFullYear()} -</span>{" "}
            <LinkText href="http://uac.gov.ua/en/" target="_blank">
              {t("vernadskyResearchBase")}
            </LinkText>
          </p>
        </div>
        {/* <div className="flex items-center gap-[15px]">
          <a
            className="transition-colors duration-300 hover:text-color-brands"
            href="/"
          >
            About
          </a>
          <a
            className="transition-colors duration-300 hover:text-color-brands"
            href="/"
          >
            Careers
          </a>
          <a
            className="transition-colors duration-300 hover:text-color-brands"
            href="/"
          >
            Policy
          </a>
          <a
            className="transition-colors duration-300 hover:text-color-brands"
            href="/"
          >
            Contact
          </a>
        </div> */}

        {showSettings && <Settings />}
      </div>
    </footer>
  );
};
Footer.propTypes = {
  showSettings: PropTypes.bool,
};

export default Footer;
