import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Popeline = () => {
  const [category, setCategory] = useState("");
  const [category1, setCategory1] = useState("");
  const { t } = useTranslation();
  const saveCategory = () => {
    const formData = {
      category,
      category1,
    };
    console.log(formData);
  };
  return (
    <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 pt-5 pb-6 lg:overflow-x-hidden mt-3 mb-6">
      <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-3 dark:text-gray-dark-1100 md:px-6 sm:px-3">
        {t("category")}
      </p>
      <div className="flex flex-col lg:flex-row items-stretch justify-center bg-neutral-bg border-neutral dark:bg-dark-neutral-bg rounded-2xl sm:px-3 pt-5 pb-5 gap-4 md:px-6">
        <div className="input-group focus:outline-none basis-6/12">
          <input
            className="w-full border rounded-lg font-normal text-sm leading-4 text-gray-400 py-4 border-[#E8EDF2] dark:border-[#313442] focus:outline-none pl-3 min-w-[152px] dark:text-gray-dark-400 bg-white dark:bg-[#1F2128]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        <div className="input-group focus:outline-none basis-6/12">
          <input
            className="w-full border rounded-lg font-normal text-sm leading-4 text-gray-400 py-4 border-[#E8EDF2] dark:border-[#313442] focus:outline-none pl-3 min-w-[152px] dark:text-gray-dark-400 bg-white dark:bg-[#1F2128]"
            value={category1}
            onChange={(e) => setCategory1(e.target.value)}
          />
        </div>
        <div className="sm:px-0 md:px-0 md:mb-2">
          {" "}
          <button
            className="mt-2 md:mt-0  py-[13px] px-6 bg-[#0077b6] rounded text-white text-xs md:text-sm"
            onClick={saveCategory}
          >
            Save
          </button>
        </div>
        <div className="sm:px-0 md:px-0 md:mb-2">
          {" "}
          <button className="mt-2 md:mt-0 py-[13px] px-6 bg-red rounded text-white text-xs md:text-sm">
            Delete
          </button>
        </div>
      </div>
      <div className="sm:px-3 md:px-6 md:mb-2">
        {" "}
        <button className="mt-2 md:mt-0  py-2 px-6 bg-[#0077b6] rounded text-white text-xs md:text-sm">
          + Add another popeline{" "}
        </button>
      </div>
    </div>
  );
};

export default Popeline;
