import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Table from "../table/table";
import {
  USER_ACTIONS_LABELS,
  USER_GROUPS_COLUMNS,
  USER_ROLES,
} from "./user-groups-settings";
import { USER_GROUPS_DATA_MOCK } from "./user-groups-data-mock";
import Pagination from "../pagination";
import LinkText from "../link/link-text";

const GROUPS_PER_PAGE = 10;

const UserGroups = ({ role = USER_ROLES.USER }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();

  const showPagination = USER_GROUPS_DATA_MOCK.length > GROUPS_PER_PAGE;
  const isAdmin = role === USER_ROLES.ADMIN;

  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  const columns = USER_GROUPS_COLUMNS.filter(
    ({ role: columnRole }) => !columnRole || columnRole.includes(role),
  );

  // todo: add fetch data from api
  const userActions = useMemo(() => {
    let actions = Object.values(USER_ACTIONS_LABELS).filter(
      ({ role: columnRole }) => !columnRole || columnRole.includes(role),
    );
    return actions.map((action) => ({
      ...action,
      onClick: () => {
        if (action.labelKey === "editGroup") {
          navigate("/account/edit-group");
        }
        // todo: add logic
        console.log(action);
      },
    }));
  }, [role, navigate]);

  // todo: add fetch data from api, remove if statements
  const data = useMemo(() => {
    let data = [];
    if (role !== USER_ROLES.USER) {
      data = USER_GROUPS_DATA_MOCK;
    }
    return data.filter(
      ({ role: columnRole }) => !columnRole || columnRole.includes(role),
    );
  }, [role]);

  return (
    <div>
      <h3 className="capitalize text-gray-1100 font-bold text-lg dark:text-gray-dark-1100 mb-6">
        {t("currentUserGroupsTitle")}
      </h3>

      <Table
        columns={columns}
        emptyDataMessage={t("userNotBelongToGroup")}
        data={data.slice(
          currentPage * GROUPS_PER_PAGE,
          (currentPage + 1) * GROUPS_PER_PAGE,
        )}
        rowActions={userActions}
      />
      {showPagination && (
        <Pagination
          className="mt-[30px] mb-[21px]"
          total={data.length}
          onClick={handlePageChange}
          currentPage={currentPage}
          itemsPerPage={GROUPS_PER_PAGE}
        />
      )}

      {isAdmin && (
        <div className="mt-8">
          <LinkText
            href="/account/add-group"
            className="inline-flex items-center gap-2 px-4 py-2
              bg-[#0077b6] rounded-lg hover:bg-[#508fda] focus:outline-none focus:ring-2
              text-white font-semibold"
          >
            <span className="text-lg">+</span>
            <span>{t("addNewGroup")}</span>
          </LinkText>
        </div>
      )}
    </div>
  );
};

UserGroups.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
};

export default UserGroups;
