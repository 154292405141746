import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

const Scale = ({ data, name, activeIndex, setActiveIndex = () => {} }) => {
  const maxValue = Math.max(...data.map((item) => item.value)) || 1;
  const hasActive =
    typeof activeIndex !== "undefined" &&
    activeIndex >= 0 &&
    activeIndex < data.length;

  console.log(hasActive, activeIndex);

  const handleClick = (data, index) => {
    if (activeIndex === index) {
      setActiveIndex();
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="w-full h-48">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
        >
          <XAxis dataKey="date" />
          <YAxis domain={[0, maxValue]} />
          <Legend />
          <Bar dataKey="value" onClick={handleClick} name={name} fill="0077b6">
            {data.map((entry, index) => (
              <Cell
                cursor="pointer"
                fill={
                  !hasActive || activeIndex === index ? "#0077b6" : "#535362"
                }
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Scale;
