export const USERS_COLUMNS = [
  {
    // key from translation
    labelKey: "tblUserEmail",
    // field key
    name: "email",
    //  ex size: w-3/12
    className: "w-3/12",
  },
  {
    // key from translation
    labelKey: "tblUserFullName",
    // field key
    name: "fullName",
    //  ex size: w-3/12
    className: "w-5/12",
  },
  {
    labelKey: "tblUserRole",
    name: "role",
    className: "w-3/12",
    type: "role",
  },
  // Note: keep 1/12 for actions
];

export const USER_ROLE_ACTIONS = {
  PROMOTE_TO_CHIEF: "promoteToChief",
  DEMOTE_TO_MEMBER: "demoteToMember",
  REMOVE: "removeFromGroup",
};

export const USER_ROLE_ACTIONS_LABELS = {
  [USER_ROLE_ACTIONS.PROMOTE_TO_CHIEF]: {
    key: USER_ROLE_ACTIONS.PROMOTE_TO_CHIEF,
    // key from translation
    labelKey: "promoteToChief",
  },
  [USER_ROLE_ACTIONS.DEMOTE_TO_MEMBER]: {
    key: USER_ROLE_ACTIONS.DEMOTE_TO_MEMBER,
    // key from translation
    labelKey: "demoteToMember",
  },
  [USER_ROLE_ACTIONS.REMOVE]: {
    key: USER_ROLE_ACTIONS.REMOVE,
    // key from translation
    labelKey: "removeFromGroup",
    className: "text-red",
  },
};
