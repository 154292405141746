import React from "react";
import PropTypes from "prop-types";

const Tags = ({
  title = "",
  tags = [],
  activeTags = [],
  onClick = () => {},
  className,
} = {}) => {
  return (
    <div
      className={
        className ||
        "border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl p-6"
      }
    >
      <div className="flex justify-between border-neutral dark:border-dark-neutral-border flex-col">
        {title && (
          <h3 className="text-base leading-5 text-gray-1100 font-semibold dark:text-gray-dark-1100 mb-[15px]">
            {title}
          </h3>
        )}

        <div className="flex items-center flex-wrap gap-4">
          {tags.map((tag, index) => (
            <button
              className={`
                rounded-[50px]
                leading-3 text-[10px]  
                py-[10px] px-[18px] 
                ${
                  activeTags.includes(tag.key)
                    ? "text-white bg-color-brands dark:bg-color-brands "
                    : "dark:text-gray-dark-500 text-gray-500 bg-neutral  dark:bg-dark-neutral-border "
                }`}
              key={tag.key || tag.name}
              onClick={() => onClick(tag.key || tag.name)}
            >
              {tag.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
Tags.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
};

export default Tags;
