export const USER_GROUPS_DATA_MOCK = [
  {
    id: "abc",
    name: "Група 1",
    description:
      "Група 128 експедиції, яка займалася вивченням підводного світу",
    chief: ["john.dou@gmail.com"],
    members: 5,
    category: "hydrometeorology",
    subCategory: "ozone",
  },
  {
    id: "cde",
    name: "Група 2",
    description:
      "Група 256 експедиції, яка займалася вивченням наводного світу",
    chief: ["jane.dou@gmail.com", "jessy.dou@gmail.com"],
    members: 1,
    category: "hydrometeorology",
    subCategory: "ozone",
  },
];

export const USERS_DATA_MOCK = [
  {
    id: "abc",
    email: "jane@gmail.com",
    fullName: "Jane Dou",
    role: "chief",
  },
  {
    id: "def",
    email: "john@gmail.com",
    fullName: "John Dou",
    role: "member",
  },
  {
    id: "gfj",
    email: "johny@gmail.com",
    fullName: "Johny Dou",
    role: "member",
  },
];
