import React from "react";
import PropTypes from "prop-types";
import { LuUser, LuEye } from "react-icons/lu";
import { TbMail } from "react-icons/tb";
import { FiPhone } from "react-icons/fi";
import { RiEyeCloseLine } from "react-icons/ri";

export const INPUT_ICONS = {
  USER: "user",
  MAIL: "mail",
  PHONE: "phone",
  PASSWORD: "password",
  PASSWORD_TEXT: "passwordText",
};

const InputIcon = ({ icon }) => {
  switch (icon) {
    case INPUT_ICONS.USER:
      return <LuUser />;
    case INPUT_ICONS.MAIL:
      return <TbMail />;
    case INPUT_ICONS.PHONE:
      return <FiPhone />;
    case INPUT_ICONS.PASSWORD:
      return <RiEyeCloseLine />;
    case INPUT_ICONS.PASSWORD_TEXT:
      return <LuEye />;
    default:
      return null;
  }
};

InputIcon.propTypes = {
  name: PropTypes.oneOf(Object.values(INPUT_ICONS)),
};

export default InputIcon;
