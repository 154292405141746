import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import BreadCrumbs from "../../components/breadcrumbs";
import Input from "../../components/form/input";
import { INPUT_ICONS } from "../../components/form/input-icon";
import { USER_ROLES } from "../../components/groups/user-groups-settings";
import Textarea from "../../components/form/textarea";
import InviteUser from "../../components/groups/invite-user";
import UserList from "../../components/groups/user-list";
import Submit from "../../components/form/submit";
import SelectBox from "../../components/form/selectbox";
import {
  CATEGORIES,
  CATEGORIES_CONFIG,
  SUB_CATEGORIES,
  SUB_CATEGORIES_CONFIG,
} from "../data/categories-settings";

const EditGroup = ({ role = USER_ROLES.ADMIN, groupId }) => {
  const { t } = useTranslation();

  const isAdmin = role === USER_ROLES.ADMIN;

  // todo: add fetch data from api
  const [nameUA, setNameUa] = useState("Геодані за 2021 рік");
  const [nameEn, setNameEn] = useState("Geodata for 2021");
  const [descriptionUa, setGroupDescriptionUa] = useState(
    "Геодані зібрані в рамках проекту",
  );
  const [descriptionEn, setGroupDescriptionEn] = useState(
    "Geodata collected within the project",
  );
  const [email, setEmail] = useState("email@gmail.com");
  const [category, setCategory] = useState(CATEGORIES.HYDROMETEOROLOGY);
  const [subCategory, setSubCategory] = useState(SUB_CATEGORIES.OZON);

  const categoriesOptions = useMemo(() => Object.values(CATEGORIES_CONFIG), []);

  const subCategoriesOptions = useMemo(
    () =>
      Object.values(SUB_CATEGORIES_CONFIG).filter(
        ({ parent }) => parent === category,
      ),
    [category],
  );

  const handleCategoryChange = (option) => {
    setCategory(option);
    setSubCategory(
      Object.values(SUB_CATEGORIES_CONFIG).find(
        ({ parent }) => parent === option,
      ) || null,
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <LayoutWithSidebar>
      <div>
        <h1 className="text-gray-1100 font-bold text-lg md:text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
          {groupId ? t("editGroupTitle") : t("addNewGroup")}
        </h1>

        <BreadCrumbs
          currentPage={t("addNewGroup")}
          links={[{ name: t("accountTitle"), link: "/account" }]}
        />

        <div className="flex flex-col gap-8">
          {/*  Загальні дані групи */}
          <section
            className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
          >
            <form
              action=""
              method=""
              onSubmit={handleSubmit}
              className="grid md:grid-cols-2 gap-6"
            >
              <Input
                id="groupNameUa"
                value={nameUA}
                label={t("groupNameLbl")}
                onChange={(e) => setNameUa(e.target.value)}
                required
              />
              <Input
                id="groupNameEn"
                value={nameEn}
                label={t("groupNameLblEn")}
                onChange={(e) => setNameEn(e.target.value)}
              />
              <Textarea
                id="groupDescription"
                value={descriptionUa}
                label={t("groupDescrLbl")}
                onChange={(e) => setGroupDescriptionUa(e.target.value)}
              />
              <Textarea
                id="groupDescriptionEn"
                value={descriptionEn}
                label={t("groupDescrLblEn")}
                onChange={(e) => setGroupDescriptionEn(e.target.value)}
              />

              <SelectBox
                id="category"
                value={category}
                options={categoriesOptions}
                onChange={handleCategoryChange}
                label={t("groupCategoryLbl")}
                disabled={!isAdmin}
              />
              <SelectBox
                id="groupSubCategory"
                label={t("groupSubCategoryLbl")}
                value={subCategory}
                onChange={setSubCategory}
                options={subCategoriesOptions}
                disabled={!isAdmin || subCategoriesOptions.length === 0}
              />

              <Input
                id="email"
                value={email}
                label={t("primaryChiefEmailLbl")}
                icon={INPUT_ICONS.MAIL}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!isAdmin}
              />

              <div className="mt-auto">
                <div className="mr-auto w-fit">
                  <Submit label={t("saveChangesBtn")} onClick={handleSubmit} />
                </div>
              </div>
            </form>
          </section>

          {/*  Перелік користувачів групи */}
          {!!groupId && (
            <section
              className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
            >
              <UserList groupId={"1"} role={USER_ROLES.CHIEF} />
            </section>
          )}

          {/*  Запросити до групи */}
          {!!groupId && (
            <section
              className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
            >
              <InviteUser groupId={"1"} />
            </section>
          )}
        </div>
      </div>
    </LayoutWithSidebar>
  );
};
EditGroup.propTypes = {
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
};

export default EditGroup;
