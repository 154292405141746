import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

const Layout = ({ children }) => {
  return (
    <div
      className="wrapper mx-auto grid scrollbar-hide grid-rows-[auto,1fr] minimize min-h-screen
        font-normal text-gray-900 dark:text-gray-dark-900
        bg-gray-100 dark:bg-[#000]"
      id="layout"
    >
      <Header />
      <main className="overflow-x-scroll scrollbar-hide flex flex-col justify-between">
        {children}
        <Footer showSettings />
      </main>
    </div>
  );
};
export default Layout;
