import React, { useEffect, useState } from "react";
// import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from "uuid";
import logo from "../../img/logo/logo.png";
const permissions = [
  {
    pipelineOutput: "Pipeline 1111: raw data",
    group: "Group Name",
    permission: "All",
    read: true,
    add: true,
    delete: false,
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1: raw data",
    group: "Group Name 1",
    permission: "Read",
    read: false,
    add: false,
    delete: true,
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1: raw data",
    group: "Group Name 2",
    permission: "Delete",
    read: true,
    add: true,
    delete: true,
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1: raw data",
    group: "Group Name 2",
    read: false,
    add: false,
    delete: true,
    permission: "Delete",
    id: uuidv4(),
  },
];
const Permissions = () => {
  // const { t } = useTranslation();

  const getUsersFromLocalStorage = () => {
    const savedUsers = localStorage.getItem("permissionsKey");
    return savedUsers ? JSON.parse(savedUsers) : permissions;
  };
  const saveUsersToLocalStorage = (permissionsArr) => {
    localStorage.setItem("permissionsKey", JSON.stringify(permissionsArr));
  };
  const [permissionsArr, setPermissionsArr] = useState(
    getUsersFromLocalStorage,
  );

  useEffect(() => {
    saveUsersToLocalStorage(permissionsArr);
  }, [permissionsArr]);

  const deleteUser = (id) => {
    setPermissionsArr(permissions.filter((permission) => permission.id !== id));
  };

  return (
    <div>
      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden mt-3 px-6  pt-[20px] pb-[20px] mb-6 ">
        <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100 px-6  pt-[20px] pb-[10px]">
          Pipeline outputs: permissions
        </p>
        <section className="flex flex-col">
          <div className="flex justify-between gap-6 mb-6 flex-col w-full">
            <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden w-full">
              <div className="overflow-x-scroll ">
                <table className="w-full">
                  <thead>
                    <tr className="">
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        Pipeline output
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        Group
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                        Permission
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissionsArr.map((permission) => (
                      <tr
                        className="dark:border-dark-neutral-border border-t rounded-[25px]"
                        key={permission.id}
                      >
                        <td className="flex">
                          <img
                            src={logo}
                            alt=""
                            className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                          />
                          <div className="flex items-center gap-[5px]">
                            <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px] whitespace-nowrap">
                              {permission.pipelineOutput}
                            </span>
                          </div>
                        </td>
                        <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                          <span className="bg-neutral text-gray-500 leading-3 rounded dark:bg-dark-neutral-border py-[10px] px-[18px] dark:text-gray-dark-500 text-[15px] mr-6 whitespace-nowrap">
                            {permission.group}
                          </span>
                        </td>
                        <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                          {permission.read === true ? (
                            <span className="bg-blue text-white  leading-3 rounded dark:bg-dark-blue-border py-[10px] px-[18px] dark:text-white text-[15px] mr-6 ">
                              Read
                            </span>
                          ) : null}

                          {permission.add === true ? (
                            <span className="bg-green text-white  leading-3 rounded dark:bg-dark-green-border py-[10px] px-[18px]  dark:text-gray-dark-500 dark:text-white text-[15px] mr-6">
                              Add
                            </span>
                          ) : null}
                          {permission.delete === true ? (
                            <span className="bg-red text-white leading-3 rounded dark:bg-dark-red-border py-[10px] px-[18px] dark:text-white text-[15px] mr-6 ">
                              Delete
                            </span>
                          ) : null}
                        </td>
                        <td className="">
                          <button
                            onClick={() => deleteUser(permission.id)}
                            className="mt-2 md:mt-0 py-2 px-6 bg-red rounded text-white text-xs md:text-sm ml-auto"
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="md:mb-2 dark:border-dark-neutral-border border-t pt-5">
                  <button className="mt-2 md:mt-0  py-2 px-6 bg-[#0077b6] rounded text-white text-xs md:text-sm">
                    {" "}
                    + Add output
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Permissions;
