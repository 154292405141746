import React from "react";
import PropTypes from "prop-types";
const ServicePage = ({
  className = "",
  total,
  onClick,
  currentPage,
  itemsPerPage = 10,
}) => {
  const len = Math.ceil(total / itemsPerPage);

  return (
    <div className={`flex items-center gap-1 flex-wrap ${className}`}>
      {Array.from({ length: len }, (_, index) => (
        <button
          type="button"
          key={index}
          onClick={() => onClick(index)}
          className={`btn border-0 shadow-none min-w-12
            ${
              currentPage === index
                ? " bg-color-brands text-white "
                : " bg-transparent text-black dark:text-white "
            } 
            text-sm capitalize leading-4 font-semibold
            hover:bg-color-brands hover:text-white focus:text-white`}
        >
          {index + 1}
        </button>
      ))}
    </div>
  );
};
ServicePage.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default ServicePage;
