import React from "react";
import Layout from "../../layout/layout";
import { useTranslation } from "react-i18next";

// todo: unify with login / registration / reset password
const SignUpSuccess = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <form
        className="rounded-2xl bg-white mx-auto p-10 text-center max-w-[440px] my-[84px] dark:bg-[#1F2128]"
        action=""
        method=""
      >
        {/* <div className="mb-4 text-center mx-auto">
          <img
            className="inline-block"
            src="assets/images/icons/icon-done.svg"
            alt="landing success"
          />
        </div> */}
        <h3 className="font-bold text-2xl text-gray-1100 capitalize mb-[5px] dark:text-gray-dark-1100">
          {t("almostDone")}
        </h3>
        <p className="text-sm text-gray-500 mb-[30px] dark:text-gray-dark-500">
          {t("lastStep")}
        </p>
        <div className="text-center mx-auto">
          {/* <div className="mb-4 text-center mx-auto">
            <img
              className="inline-block"
              src="assets/images/icons/icon-done-mail.png"
              alt="landing success"
            />
          </div> */}
          <p className="text-md text-gray-1100 mb-[30px] dark:text-gray-dark-1100">
            {t("weSentAnEmail")}
            <a href="/">foxdashboard@hotmail.com</a>
            <br />
            {t("clickConfirmationLink")}
          </p>
          <button className="btn normal-case h-fit min-h-fit transition-all duration-300 border-4 w-full border-neutral-bg mb-[20px] py-[14px] dark:border-dark-neutral-bg">
            {t("openEmailApp")}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default SignUpSuccess;
