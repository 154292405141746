import React from "react";
import { TbFilterSearch } from "react-icons/tb";
import { LuUser } from "react-icons/lu";
import { MdOutlineArrowBackIosNew, MdAttachFile } from "react-icons/md";
import logo from "../../img/logo/logo.png";
import { IoIosArrowForward } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Settings from "../settings/settings";
import { useTranslation } from "react-i18next";
import LinkText from "../link/link-text";

const items = [
  { id: 1, labelKey: "dataTitle", link: "/data", icon: MdAttachFile },
  {
    id: 3,
    labelKey: "accountTitle",
    link: "/account",
    icon: LuUser,
  },
];

const Navbar = ({ isSideBarHidden, toggleSidebar, content = null }) => {
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();

  const hasContent = content !== null;

  return (
    <aside
      className="
        relative flex flex-col justify-between row-span-2 p-4 gap-4 h-full
        border-r border-neutral bg-white dark:bg-dark-neutral-bg dark:border-dark-neutral-border
        text-normal text-gray-500 dark:text-gray-dark-500"
    >
      <button
        className={`fixed 
            top-5 p-2 ${isSideBarHidden ? "left-[46px]" : "left-[224px]"}
            border-neutral border bg-white rounded-full dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            translate-x-1/2 duration-300 hover:opacity-75 
            cursor-pointer z-50`}
        onClick={toggleSidebar}
      >
        {!isSideBarHidden ? (
          <MdOutlineArrowBackIosNew />
        ) : (
          <IoIosArrowForward />
        )}
      </button>
      <div className="flex items-center gap-3 z-40">
        <LinkText className="flex items-center text-center gap-3 pl-2" href="/">
          <img
            className="md:h-[40px] lg:h-[40px] h-[40px] xs:h-[30px]"
            src={logo}
            alt="Vernadsky Research Base"
          />
          {!isSideBarHidden && (
            <span
              className="font-light text-2l leading-5 text-gray-900
                bg-neutral-bg dark:text-gray-300 dark:bg-dark-neutral-bg"
            >
              {t("vernadskyResearchBaseShort")}
            </span>
          )}
        </LinkText>
      </div>

      <menu>
        {items.map((item) => (
          <li
            key={item.id}
            className={`rounded-xl mb-3 relative ${pathname.includes(item.link) ? "bg-[var(--color-brands)] text-gray-100" : ""}`}
          >
            <LinkText
              href={item.link}
              className={`flex items-center gap-4 cursor-pointer p-3
                focus:outline-none ${pathname.includes(item.link) ? "text-gray-100" : ""}`}
            >
              <span className="flex justify-center h-[16px] w-[20px]">
                <item.icon />
              </span>
              {!isSideBarHidden && (
                <span className="text-normal font-semibold">
                  {t(item.labelKey)}
                </span>
              )}
            </LinkText>
          </li>
        ))}
      </menu>

      <div className="mb-auto">
        {hasContent && (
          <div className="border-t border-neutral dark:border-dark-neutral-border pt-8">
            {isSideBarHidden ? (
              <span className="flex justify-center h-[16px] w-[40px]">
                <TbFilterSearch />
              </span>
            ) : (
              content
            )}
          </div>
        )}
      </div>

      <div className="mx-auto">
        <Settings isCollapsed={isSideBarHidden} />
      </div>
    </aside>
  );
};

export default Navbar;
