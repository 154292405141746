import React, { useEffect, useState } from "react";
import { TbHome } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Tags from "../../components/tags";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import Dropdown from "./dropdown";
import Pagination from "../../components/pagination";

const tags = [
  { name: "Marketing", link: "/uploads" },
  { name: "Adobe XD", link: "/uploads" },
  { name: "PSD Editor", link: "/uploads" },
  { name: "Employer", link: "/uploads" },
  { name: "Online Jobs", link: "/uploads" },
  { name: "Remote Work", link: "/uploads" },
  { name: "Salary", link: "/uploads" },
  { name: "Tips", link: "/uploads" },
  { name: "Income", link: "/uploads" },
  { name: "Develop", link: "/uploads" },
  { name: "App", link: "/uploads" },
  { name: "Digital", link: "/uploads" },
];

const array = [
  {
    id: uuidv4(),
    name: "dahsboard_design.fig",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "macbook_pro.jpg",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "index.html",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "code.zip",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "sound_bird.mp3",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "full_trailer_2.mp4",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "dahsboard_design.fig",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "macbook_pro.jpg",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "dahsboard_design.fig",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "dahsboard_design.fig",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "code.zip",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "dahsboard_design.fig",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "index.html",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "code.zip",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "dahsboard_design.fig",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "macbook_pro.jpg",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "index.html",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "code.zip",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "sound_bird.mp3",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "full_trailer_2.mp4",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
  {
    id: uuidv4(),
    name: "dahsboard_design.fig",
    category: "2.4Mb",
    date: "Aug 5th, 2022",
    status: "Mac Image",
    checked: false,
  },
];
export const STATUSES = {
  FAILED: { value: "FAILED", name: "Failed" },
  IN_PROGRESS: { value: "IN_PROGRESS", name: "In Progress" },
  SUCCESS: { value: "SUCCESS", name: "Success" },
};
const Index = () => {
  const [mainChecked, setMainChecked] = useState(false);
  const [subCheckboxes, setSubCheckboxes] = useState(array);
  const { t } = useTranslation();
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  // статус
  let statusColor;
  let statusText;
  let spanColor;

  switch (STATUSES.value) {
    case STATUSES.FAILED.value:
      spanColor = "bg-[var(--danger)]";
      statusColor = "text-[var(--danger)]";
      statusText = STATUSES.FAILED.name;
      break;
    case STATUSES.IN_PROGRESS.value:
      spanColor = "bg-[var(--secondary)]";
      statusColor = "text-[var(--secondary)]";
      statusText = STATUSES.IN_PROGRESS.name;
      break;
    case STATUSES.SUCCESS.value:
      spanColor = "bg-[var(--emerald-accent)]";
      statusColor = "text-[var(--emerald-accent)";
      statusText = STATUSES.SUCCESS.name;
      break;
    default:
      spanColor = "bg-[var(--secondary)]";
      statusColor = "text-[var(--secondary)]";
      statusText = "Unknown";
  }
  // статус
  useEffect(() => {
    const savedPage = localStorage.getItem("currentPage");
    if (savedPage) {
      setCurrentPage(Number(savedPage));
    }
  }, []);

  const handleMainCheckboxChange = () => {
    const newMainChecked = !mainChecked;
    setMainChecked(newMainChecked);
    setSubCheckboxes(
      subCheckboxes.map((item) => ({ ...item, checked: newMainChecked })),
    );
  };

  const handleSubCheckboxChange = (id) => {
    const updatedCheckboxes = subCheckboxes.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item,
    );
    setSubCheckboxes(updatedCheckboxes);
    setMainChecked(updatedCheckboxes.every((item) => item.checked));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = subCheckboxes.slice(
    startIndex,
    startIndex + itemsPerPage,
  );

  return (
    <LayoutWithSidebar>
      <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
        {t("fileManage")}
      </h2>
      <div className="flex justify-between flex-col gap-y-2 sm:flex-row mb-[31px]">
        <div className="flex items-center text-xs gap-x-[11px]">
          <a className="flex items-center gap-x-1" href="/">
            <TbHome />
            <span className="capitalize text-gray-500 dark:text-gray-dark-500">
              {t("home")}
            </span>
          </a>
          <IoIosArrowForward />
          <span className="capitalize text-color-brands">
            {" "}
            {t("fileManage")}
          </span>
        </div>
      </div>

      <div>
        <section>
          <div>
            <div className="flex justify-between gap-6 mb-6 flex-col xl:flex-row z-0">
              <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden basis-8/12 z-0">
                <div className="overflow-x-auto ">
                  <table className="w-full min-w-[852px] lg:min-w-fit ">
                    <thead>
                      <tr>
                        <th className="text-left pb-3">
                          <input
                            className="checkbox checkbox-bg-11 rounded border-color-brands w-[18px] h-[18px] translate-y-[3px] mr-[26px] checkbox:border-color-brands checkbox:bg-bg-11  "
                            type="checkbox"
                            checked={mainChecked}
                            onChange={handleMainCheckboxChange}
                          />
                        </th>
                        <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                          {t("name")}
                        </th>
                        <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                          {t("category")}
                        </th>
                        <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                          {t("date")}
                        </th>
                        <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                          {t("status")}
                        </th>
                        <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                          {t("actions")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="z-5">
                      {currentItems.map((ar, index) => (
                        <tr
                          key={index}
                          className="dark:border-dark-neutral-border "
                        >
                          <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border ">
                            <input
                              className={`checkbox  rounded border-color-brands w-[18px] h-[18px] translate-y-[3px] mr-[26px]
                                  ${ar.checked ? "bg-[#0077b6]" : ""}`}
                              type="checkbox"
                              checked={ar.checked}
                              onChange={() => handleSubCheckboxChange(ar.id)}
                            />
                          </td>
                          <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                            <div className="flex items-center gap-[5px]">
                              <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                                {ar.name}
                              </span>
                            </div>
                          </td>
                          <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                            <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                              {ar.category}
                            </span>
                          </td>
                          <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                            <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[67px]">
                              {ar.date}
                            </span>
                          </td>
                          <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                            <div className="flex items-center justify-between w-32 p-2 rounded-full border-2 transition-colors duration-300">
                              <div
                                className={`w-3 h-3 rounded-full ${spanColor}`}
                              ></div>
                              <span
                                className={`text-sm font-semibold ${statusColor}`}
                              >
                                {statusText}
                              </span>
                              <span className="text-black"></span>
                            </div>
                          </td>
                          <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border z-100">
                            <Dropdown />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  className="mt-[30px] mb-[21px]"
                  total={subCheckboxes.length}
                  onClick={handlePageChange}
                  currentPage={currentPage}
                />
              </div>

              <div className="flex flex-col gap-6 flex-1 basis-3/12">
                <Tags tags={tags} onClick={() => {}} title={t("dataTags")} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </LayoutWithSidebar>
  );
};

export default Index;
