import React from "react";
import PropTypes from "prop-types";
import InputIcon, { INPUT_ICONS } from "./input-icon";

const Textarea = ({
  label = "",
  disabled = false,
  readonly = false,
  required = false,
  value = "",
  placeholder = "",
  onChange = () => {},
  icon = null,
  id,
}) => {
  return (
    <label className="w-full flex flex-col gap-4">
      {!!label && (
        <span className="text-gray-1100 text-base leading-4 font-medium dark:text-gray-dark-1100">
          {label}
          {required && " *"}
        </span>
      )}
      <div
        className={`h-fit w-full min-h-fit flex items-center
                border border-neutral dark:border-gray-800 rounded-lg
                bg-transparent focus:outline-none disabled:bg-transparent disabled:cursor-not-allowed
                placeholder:text-inherit`}
      >
        <textarea
          id={id}
          name={id}
          className={`textarea w-full p-4
            text-gray-800 focus:outline-none resize-none rounded-none bg-transparent min-h-[140px]
            focus:border-0 dark:text-gray-dark-800 placeholder:text-inherit border-0 border-transparent box-border
            ${disabled ? "text-gray-400" : ""}`}
          placeholder={placeholder}
          disabled={disabled || readonly}
          value={value}
          onChange={onChange}
          required={required}
          maxLength={255}
        />
        {icon && (
          <span className="px-3 flex-grow-0">
            <InputIcon icon={icon} />
          </span>
        )}
      </div>
    </label>
  );
};

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.oneOf(Object.values(INPUT_ICONS)),
};

export default Textarea;
