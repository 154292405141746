import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import LinkText from "../../components/link/link-text";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col relative">
      <div
        className="absolute w-[100%] h-[100%] -z-10
        bg-cover bg-center bg-[url('/src/img/home-day-drone.jpg')] dark:bg-top dark:bg-[url('/src/img/home-night.jpg')]"
      ></div>
      <Header />
      <div
        className="flex-grow flex flex-wrap
        bg-gradient-to-b from-[#00263b] dark:from-gray-1100
        from-50% md:bg-gradient-to-l xl:from-10% dark:md:from-10%
        font-normal text-gray-100 dark:text-gray-300 z-20"
      >
        <div className="w-0 md:w-1/4 xl:w-1/2"></div>

        <div className="w-full md:w-3/4 xl:w-1/2 p-8 sm:p-4 lg:p-20 md:p-10">
          <h1 className="text-2xl mb-4">{t("homeWelcome")}</h1>
          <p className="text-xl mb-2">{t("homeIntro")}</p>
          <p className="mb-2">
            <Trans
              i18nKey="homeText"
              components={{
                nasc_link: (
                  <LinkText
                    href="http://uac.gov.ua/en/"
                    target="_blank"
                    className="underline hover:no-underline"
                  />
                ),
                structure_link: (
                  <LinkText
                    href="/explore"
                    className="underline hover:no-underline"
                  />
                ),
              }}
            />
          </p>

          <div className="mt-8">
            <LinkText
              href="/explore"
              className="btn h-fit min-h-fit transition-all duration-300
              border-4 border-neutral-bg py-4
              dark:border-color-brands dark:bg-color-brands dark:text-gray-100"
            >
              {t("homeExplore")}
            </LinkText>
          </div>

          {/*
          <div className="mt-[50px]">
            <a
              href="/login"
              className="btn normal-case h-fit min-h-fit transition-all duration-300 border-4border-neutral-bg mb-[20px] py-[14px] dark:border-dark-neutral-bg mr-[20px]"
            >
              login
            </a>
            <a
              href="/registration"
              className="btn normal-case h-fit min-h-fit transition-all duration-300 border-4border-neutral-bg mb-[20px] py-[14px] dark:border-dark-neutral-bg"
            >
              registration
            </a>
          </div> */}
        </div>
      </div>
      <div className="bg-neutral-bg dark:bg-dark-neutral-bg">
        <Footer showSettings={true} />
      </div>
    </div>
  );
};

export default HomePage;
