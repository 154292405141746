const EMAIL_REGEX =
  /^[^\W_]+\w*(?:[.-]\w*)*[^\W_]+@[^\W_]+(?:[.-]?\w*[^\W_]+)*(?:\.[^\W_]{2,})$/;

export const getIsEmailInValid = (email) =>
  !!email && !email.match(EMAIL_REGEX);

export const MIN_LENGTH_PASSWORD = 6;
export const MAX_LENGTH_PASSWORD = 20;

export const getIsPasswordInValid = (password, confirmPassword) =>
  (!!password &&
    password.length < MIN_LENGTH_PASSWORD &&
    password.length > MAX_LENGTH_PASSWORD) ||
  (confirmPassword && confirmPassword !== password);
