import React, { useState } from "react";
import Layout from "../../layout/layout";
import { useTranslation } from "react-i18next";
import logo from "../../img/logo/logo.png";
import Input from "../../components/form/input";
import { INPUT_ICONS } from "../../components/form/input-icon";
import Submit from "../../components/form/submit";
import LinkText from "../../components/link/link-text";
import { getIsEmailInValid } from "./constants";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const isEmailInValid = getIsEmailInValid(email);
  const isFormValid = !!email && !isEmailInValid;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Данні форми:", email);
  };

  return (
    <Layout>
      <div>
        <form
          className="rounded-2xl bg-white mx-auto p-10 text-center max-w-[440px] my-[84px] dark:bg-[#1F2128]"
          action=""
          method=""
          onSubmit={handleSubmit}
        >
          <div className="mb-4 text-center mx-auto">
            <img
              src={logo}
              alt="logo"
              className="rounded-[50%] w-[60px] sm:w-[80px] lg:w-[80px] xl:w-[100px] mx-auto"
            />
          </div>
          <h3 className="font-bold text-2xl text-gray-1100 capitalize mb-[5px] dark:text-gray-dark-1100">
            {t("resetPasswordTitle")}
          </h3>
          <p className="text-sm text-gray-500 mb-[30px] dark:text-gray-dark-500">
            {t("resetPasswordSubtitle")}
          </p>

          <div className="flex flex-col gap-4 text-left">
            <Input
              type="email"
              label={t("email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              icon={INPUT_ICONS.MAIL}
              required
            />

            <div className="text-red text-sm">
              {isEmailInValid && <p>{t("emailNotValid")}</p>}
            </div>

            <Submit label={t("resetPasswordBtn")} disabled={!isFormValid} />
          </div>

          <p className="text-sm text-gray-500 mt-8">
            {t("dontHaveAccount")}{" "}
            <LinkText href="/registration" isPlain={false}>
              {t("registrationBtn")}
            </LinkText>
          </p>
          <p className="text-sm text-gray-500 mt-2">
            {t("knowThePassword")}{" "}
            <LinkText href="/login" isPlain={false}>
              {t("login")}
            </LinkText>
          </p>
        </form>
      </div>
    </Layout>
  );
};

export default ResetPasswordPage;
