import React from "react";
import logo from "../../img/logo/logo.png";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LinkText from "../link/link-text";

const Header = () => {
  const { t } = useTranslation();
  return (
    <header
      className="flex justify-between align-items-center flex-wrap gap-5
      py-2 px-4 md:py-4 lg:flex-nowrap lg:gap-0
      font-extralight text-gray-900
      bg-neutral-bg dark:text-gray-300 dark:bg-dark-neutral-bg"
    >
      <LinkText className="flex gap-5 items-center" href="/">
        <img
          className="h-[30px] md:h-[40px]"
          src={logo}
          alt={t("vernadskyResearchBase")}
        />
        <span className="text-2xl">{t("vernadskyResearchBaseShort")}</span>
      </LinkText>
    </header>
  );
};

Header.propTypes = {
  isSideBarHidden: PropTypes.bool,
};

export default Header;
