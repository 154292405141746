export const USER_ACTIONS = {
  LEAVE: "leaveGroup",
  EDIT: "editGroup",
  DELETE: "deleteGroup",
};

export const USER_ROLES = {
  ADMIN: "admin",
  CHIEF: "chief",
  MEMBER: "member",
  USER: "user",
};

export const USER_ACTIONS_LABELS = {
  [USER_ACTIONS.LEAVE]: {
    // key from translation
    labelKey: "leaveGroup",
    className: "text-red",
    role: [USER_ROLES.MEMBER],
  },
  [USER_ACTIONS.EDIT]: {
    // key from translation
    labelKey: "editGroup",
    role: [USER_ROLES.CHIEF, USER_ROLES.ADMIN],
  },
  [USER_ACTIONS.DELETE]: {
    // key from translation
    labelKey: "deleteGroup",
    className: "text-red",
    role: [USER_ROLES.ADMIN],
  },
};

export const USER_GROUPS_COLUMNS = [
  {
    // key from translation
    labelKey: "tblGroupName",
    // field key
    name: "name",
    // helper key for sub text
    helperKey: "description",
    //  ex size: w-3/12
    className: "w-4/12",
  },
  {
    labelKey: "tblGroupChief",
    name: "chief",
    className: "w-2/12",
  },
  {
    labelKey: "groupCategoryLbl",
    name: "category",
    className: "w-2/12",
    type: "category",
  },
  {
    labelKey: "groupSubCategoryLbl",
    name: "subCategory",
    className: "w-2/12",
    type: "subCategory",
  },
  {
    labelKey: "tblGroupMembers",
    name: "members",
    className: "w-1/12",
    role: [USER_ROLES.CHIEF, USER_ROLES.ADMIN],
  },
  // Note: keep 1/12 for actions
];
