import React from "react";
import PropTypes from "prop-types";
import Language from "../../locales/language";
import ThemeSwitch from "./theme-switch";

const Settings = ({ isCollapsed = false }) => {
  return (
    <div
      className={`p-2
        flex justify-items-center items-center gap-3
        ${isCollapsed ? "flex-col" : ""}
        rounded-xl bg-neutral dark:bg-dark-neutral-border`}
    >
      <ThemeSwitch />
      <div
        className={`${isCollapsed ? "w-[100%] h-[1px]" : "w-[1px] h-[20px]"} bg-gray-500 opacity-50`}
      ></div>
      <Language isCollapsed={isCollapsed} />
    </div>
  );
};
Settings.propTypes = {
  isCollapsed: PropTypes.bool,
};

export default Settings;
