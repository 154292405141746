import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SelectBox = ({
  label = "",
  disabled = false,
  readonly = false,
  required = false,
  value = "",
  options = [],
  placeholder = "",
  onChange = () => {},
  id,
}) => {
  const { t } = useTranslation();
  return (
    <label className="w-full flex flex-col gap-4">
      {!!label && (
        <span className="text-gray-1100 text-base leading-4 font-medium dark:text-gray-dark-1100">
          {label}
          {required && " *"}
        </span>
      )}
      <div className="h-fit w-full min-h-fit flex items-center">
        <select
          id={id}
          name={id}
          required={required}
          disabled={disabled || readonly}
          className="
            select
            w-full lg:min-w-[252px] py-4 pl-3 h-fit
            border rounded-lg border-neutral dark:border-gray-800 focus:outline-none bg-transparent
            font-normal text-sm leading-4 text-gray-400 dark:text-gray-dark-400
            disabled:opacity-50 disabled:bg-transparent disabled:text-gray-400 disabled:border-neutral"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {placeholder && (
            <option disabled defaultValue="">
              {placeholder}
            </option>
          )}
          {options.map(({ value, label, labelKey }) => (
            <option key={value} value={value}>
              {(labelKey && t(labelKey)) || label || value}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
};

SelectBox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      labelKey: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default SelectBox;
