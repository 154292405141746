import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const LANGUAGE = {
  EN: "en",
  UA: "ua",
};

export const LOCAL_STORAGE_LANGUAGE_KEY = "language";

const enGeneric = require("./locales/en/translation.json");
const enAccount = require("./locales/en/translation-account.json");
const enCategories = require("./locales/en/translation-categories.json");
const enData = require("./locales/en/translation-data.json");

const uaGeneric = require("./locales/ua/translation.json");
const uaAccount = require("./locales/ua/translation-account.json");
const uaCategories = require("./locales/ua/translation-categories.json");
const uaData = require("./locales/ua/translation-data.json");

i18n.use(initReactI18next).init({
  languages: [LANGUAGE.EN, LANGUAGE.UA],
  resources: {
    en: {
      translation: { ...enGeneric, ...enAccount, ...enCategories, ...enData },
    },
    ua: {
      translation: { ...uaGeneric, ...uaAccount, ...uaCategories, ...uaData },
    },
  },
  lng: localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) || LANGUAGE.UA,
  fallbackLng: LANGUAGE.EN, // Резервна мова
  interpolation: {
    escapeValue: false, // React вже робить XSS-обробку
  },
});

export default i18n;
