import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/breadcrumbs";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import Categories from "./categories";

const DataCategories = () => {
  const { t } = useTranslation();
  return (
    <LayoutWithSidebar>
      <div>
        <h1 className="text-gray-1100 font-bold text-lg md:text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
          {t("dataTitle")}
        </h1>

        <BreadCrumbs currentPage={t("categoriesTitle")} />

        <section>
          <Categories />
        </section>
      </div>
    </LayoutWithSidebar>
  );
};

export default DataCategories;
