import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const BooleanState = ({ value }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center w-[70px] p-2 gap-2 rounded-full border border-gray-700 transition-colors duration-300">
      <div
        className={`w-3 h-3 rounded-full ${value ? "bg-green" : "bg-neutral"}`}
      />
      <span
        className={`text-sm font-semibold ${value ? "text-green" : "text-neutral"}`}
      >
        {value ? t("yes") : t("no")}
      </span>
    </div>
  );
};
BooleanState.propTypes = {
  value: PropTypes.bool,
};

export default BooleanState;
