import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Submit from "../form/submit";
import Input from "../form/input";
import { INPUT_ICONS } from "../form/input-icon";
import SelectBox from "../form/selectbox";
import { getIsEmailInValid } from "../../pages/auth/constants";
import { USER_ROLES } from "./user-groups-settings";

const ROLE_OPTIONS = [
  {
    labelKey: "roleMember",
    value: USER_ROLES.MEMBER,
  },
  {
    labelKey: "roleAdmin",
    value: USER_ROLES.CHIEF,
  },
];

const InviteUser = ({ groupId }) => {
  const { t } = useTranslation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(USER_ROLES.MEMBER);

  const isEmailInValid = getIsEmailInValid(email);
  const isFormValid = email && role && !isEmailInValid;

  // todo: add request
  const handleSubmit = (e) => {
    setIsUpdating(true);
    console.log("Данні форми:", email, role);
    e.preventDefault();
  };

  return (
    <div>
      <h3 className="text-gray-1100 font-bold text-lg dark:text-gray-dark-1100 mb-6">
        {t("inviteTitle")}
      </h3>

      <form
        className="flex content-end gap-6 flex-col md:flex-row"
        action=""
        method=""
        onSubmit={handleSubmit}
      >
        <div className="basis-4/12 mt-auto">
          <Input
            label={t("email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            required
            disabled={isUpdating}
            icon={INPUT_ICONS.MAIL}
          />
        </div>
        <div className="basis-4/12 mt-auto">
          <SelectBox
            id="role"
            label={t("roleLabel")}
            options={ROLE_OPTIONS}
            value={role}
            onChange={(value) => setRole(value)}
          />
        </div>
        <div className="basis-4/12 mt-auto">
          <Submit
            label={t("inviteBtn")}
            onClick={handleSubmit}
            disabled={!isFormValid}
          />
        </div>
      </form>

      <div className="text-red text-sm">
        {isEmailInValid && <p className="mt-4">{t("emailNotValid")}</p>}
      </div>
    </div>
  );
};

InviteUser.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default InviteUser;
