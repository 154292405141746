import React from "react";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  PieChart,
  Pie,
  Cell,
} from "recharts";
const dataScale = [
  { name: "Google", value: 44 },
  { name: "Facebook", value: 40 },
  { name: "Pinterest", value: 26 },
];
// React.useEffect(() => {
//     fetch("/api/data-endpoint")
//         .then((response) => response.json())
//         .then((data) => setData(data));
// }, []);

const data = [
  { month: "01", Order: 50, Refunds: 75, Earnings: 100 },
  { month: "02", Order: 70, Refunds: 90, Earnings: 120 },
  { month: "03", Order: 90, Refunds: 100, Earnings: 150 },
  { month: "04", Order: 100, Refunds: 110, Earnings: 170 },
  { month: "05", Order: 110, Refunds: 120, Earnings: 180 },
  { month: "06", Order: 130, Refunds: 130, Earnings: 200 },
  { month: "07", Order: 140, Refunds: 140, Earnings: 220 },
  { month: "08", Order: 150, Refunds: 160, Earnings: 230 },
  { month: "09", Order: 170, Refunds: 180, Earnings: 240 },
  { month: "10", Order: 180, Refunds: 190, Earnings: 250 },
  { month: "11", Order: 200, Refunds: 210, Earnings: 270 },
  { month: "12", Order: 220, Refunds: 230, Earnings: 300 },
];

// const [data, setData] = React.useState([]);

// React.useEffect(() => {
//     fetch("/api/monthly-data")
//         .then((res) => res.json())
//         .then((data) => setData(data));
// }, []);
const Charts = () => {
  const COLORS = ["#3b82f6", "#34d399", "#ef4444"];
  return (
    <LayoutWithSidebar>
      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 pt-5 pb-6 lg:overflow-x-hidden mt-3 mb-6 ">
        <div className="p-4 rounded-lg shadow-md bg-white dark:bg-dark-neutral-bg dark:border-dark-neutral-border border rounded-[25px]  ">
          <h2 className="text-lg font-semibold mb-4">Monthly Data</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Area
                type="monotone"
                dataKey="Order"
                stackId="1"
                stroke="#3b82f6"
                fill="#93c5fd"
                strokeWidth={2}
              />
              <Area
                type="monotone"
                dataKey="Refunds"
                stackId="1"
                stroke="#ef4444"
                fill="#fecaca"
                strokeWidth={2}
              />
              <Area
                type="monotone"
                dataKey="Earnings"
                stackId="1"
                stroke="#22c55e"
                fill="#bbf7d0"
                strokeWidth={2}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 pt-5 pb-6 lg:overflow-x-hidden mt-3 mb-6">
        <div className="p-4 bg-white  dark:bg-dark-neutral-bg dark:border-dark-neutral-border border rounded-[25px]  rounded-lg shadow-md max-w-sm mx-auto">
          <h2 className="text-lg font-semibold text-gray-800 mb-4  dark:text-gray-dark-1100">
            Top Customer
          </h2>
          <div className="flex justify-center">
            <PieChart width={280} height={250}>
              <Pie
                data={dataScale}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="55%"
                innerRadius={60}
                outerRadius={100}
                paddingAngle={2}
                fill="#8884d8"
                label={({ name, percent }) => ` ${(percent * 100).toFixed(0)}%`}
              >
                {dataScale.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>

          <ul className="mt-4">
            {dataScale.map((entry, index) => (
              <li key={index} className="flex items-center text-sm mb-2">
                <div
                  className="w-3 h-3 rounded-full mr-2"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                ></div>
                <span className="text-gray-700">{entry.name}</span>
              </li>
            ))}
          </ul>
          <p className="text-sm text-gray-500 mt-4">
            Frox is a clean and modern dashboard UI kit targeting a wide variety
            of use cases for web applications in light and dark mode.
          </p>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default Charts;
