import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/breadcrumbs";
import Input from "../../components/form/input";
import { INPUT_ICONS } from "../../components/form/input-icon";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import UpdatePassword from "../../components/form/edit-password";
import UserGroups from "../../components/groups/user-groups";
import { USER_ROLES } from "../../components/groups/user-groups-settings";

const Account = ({ role = USER_ROLES.USER }) => {
  const { t } = useTranslation();

  // todo: add fetch data from api
  const firstName = "John";
  const lastName = "Dou";
  const email = "email@gmail.com";
  const organization = "NASC";
  const position = "Research Officer";
  const country = "Ukraine";

  return (
    <LayoutWithSidebar>
      <div>
        <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
          {t("accountTitle")}
        </h2>

        <BreadCrumbs currentPage={t("accountTitle")} />

        <div className="flex flex-col gap-8">
          {/*Дані користувача та організації*/}
          <section
            className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
          >
            <div className="flex justify-between gap-6 flex-col xl:flex-row">
              <div className="flex flex-col items-start gap-6 basis-6/12">
                <Input
                  id="firstName"
                  value={firstName}
                  label={t("firstName")}
                  icon={INPUT_ICONS.USER}
                  readonly
                />
                <Input
                  id="lastName"
                  value={lastName}
                  label={t("lastName")}
                  icon={INPUT_ICONS.USER}
                  readonly
                />
                <Input
                  id="email"
                  value={email}
                  label={t("email")}
                  icon={INPUT_ICONS.MAIL}
                  readonly
                />
              </div>

              <div className="flex flex-col items-start gap-6 basis-6/12">
                <Input
                  id="country"
                  value={country}
                  label={t("country")}
                  readonly
                />
                <Input
                  id="organization"
                  value={organization}
                  label={t("organization")}
                  readonly
                />
                <Input
                  id="position"
                  value={position}
                  label={t("position")}
                  readonly
                />
              </div>
            </div>
          </section>

          {/*Зміна паролю*/}
          <section
            className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
          >
            <UpdatePassword
              className="flex content-end gap-6 flex-col md:flex-row"
              rowClassName="basis-4/12 mt-auto"
            />
          </section>

          {/* Групи */}
          <section
            className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
          >
            <UserGroups role={role} />
          </section>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};
Account.propTypes = {
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
};

export default Account;
