import React, { useEffect, useState } from "react";
import Dropdown from "./dropdown";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Pagination from "../../components/pagination";
const array = [
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
  {
    id: uuidv4(),
    fileName: "dahsboard_design.fig",
    category: "2.4Mb",
    size: "2.4Mb",
    created: "Aug 5th, 2022",
    date: "Aug 5th, 2022",
    pipeline: "Pipeline 1",
    status: "Processing",
    user: "email1@gmail.com",
    checked: false,
  },
];
const Uploads = () => {
  const [subCheckboxes] = useState(array);
  const { t } = useTranslation();
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const savedPage = localStorage.getItem("currentPage");
    if (savedPage) {
      setCurrentPage(Number(savedPage));
    }
  }, []);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = subCheckboxes.slice(
    startIndex,
    startIndex + itemsPerPage,
  );

  return (
    <div className="flex justify-between gap-6 mb-6 flex-col w-full">
      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden w-full">
        <div className="overflow-x-auto ">
          <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
            User
          </h2>
          <table className="w-full min-w-[852px] lg:min-w-fit ">
            <thead>
              <tr>
                <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                  File Name
                </th>
                <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                  Size
                </th>
                <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                  Created
                </th>
                <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                  {t("status")}
                </th>
                <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                  User
                </th>
                <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                  {t("actions")}
                </th>
              </tr>
            </thead>
            <tbody className="z-5">
              {currentItems.map((ar, index) => (
                <tr key={index} className="dark:border-dark-neutral-border ">
                  <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                    <div className="flex items-center gap-[5px]">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                        {ar.fileName}
                      </span>
                    </div>
                  </td>
                  <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                    <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                      {ar.size}
                    </span>
                  </td>
                  <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                    <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[67px]">
                      {ar.date}
                    </span>
                  </td>
                  <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                    <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[67px]">
                      {ar.status}
                    </span>
                  </td>
                  <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                    <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[67px]">
                      {ar.user}
                    </span>
                  </td>

                  <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border z-100">
                    <Dropdown />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            className="mt-[30px] mb-[21px]"
            total={subCheckboxes.length}
            onClick={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Uploads;
