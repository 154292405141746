import React, { useState } from "react";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";

const LOCAL_STORAGE_SIDEBAR_KEY = "sidebar-hidden";

export function setSidebarVisibility(isHidden) {
  const isSidebarHidden =
    isHidden ?? localStorage.getItem(LOCAL_STORAGE_SIDEBAR_KEY) === "true";
  localStorage.setItem(LOCAL_STORAGE_SIDEBAR_KEY, isSidebarHidden.toString());
  return isSidebarHidden;
}

const LayoutWithSidebar = ({ children, sidebarContent = null }) => {
  const [isSideBarHidden, setIsSideBarHidden] = useState(
    setSidebarVisibility(),
  );
  const toggleSidebar = () => {
    setIsSideBarHidden(!isSideBarHidden);
    setSidebarVisibility(!isSideBarHidden);
  };
  return (
    <div
      className={`wrapper mx-auto 
      font-normal text-gray-900 dark:text-gray-dark-900 
      bg-gray-100 dark:bg-[#000]
      h-screen grid scrollbar-hide ${isSideBarHidden ? "grid-cols-[80px,auto]" : "grid-cols-[257px,auto]"}`}
      id="layout"
    >
      <Navbar
        toggleSidebar={toggleSidebar}
        isSideBarHidden={isSideBarHidden}
        content={sidebarContent}
      />
      <div className="overflow-x-scroll min-h-screen scrollbar-hide flex flex-col justify-between w-full">
        <main className="pt-[42px] px-[23px] pb-[28px]">{children}</main>
        <Footer showSettings={false} />
      </div>
    </div>
  );
};
export default LayoutWithSidebar;
