import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import { useTranslation } from "react-i18next";

import Permissions from "./permissions";
import Output from "./output";
import Popeline from "./popeline";
import Uploads from "./uploads";
import { useState } from "react";

const EditExperiment = () => {
  const { t } = useTranslation();
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [textarea1, setTextarea1] = useState("");
  const [textarea2, setTextarea2] = useState("");
  const [dropdown1, setDropdown1] = useState("");
  const [dropdown2, setDropdown2] = useState("");

  const handleSave = () => {
    const formData = {
      input1,
      input2,
      textarea1,
      textarea2,
      dropdown1,
      dropdown2,
    };
    console.log(formData);
  };

  return (
    <LayoutWithSidebar>
      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden ">
        <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px] px-6 ">
          Edit Experiment
        </h2>
        <div className="flex justify-between gap-6 mb-6 flex-col xl:flex-row">
          <div className="lg:overflow-x-hidden basis-6/12 sm:px-3 pt-5 pb-5 gap-4 md:px-6">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Name
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-12 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="Type name here"
                value={input1}
                onChange={(e) => setInput1(e.target.value)}
              />
            </div>
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Description
            </p>
            <div className="rounded-lg border border-neutral dark:border-dark-neutral-border p-[13px]">
              <textarea
                className="textarea w-full p-0 text-gray-800 focus:outline-none resize-none rounded-none bg-transparent min-h-[140px] 
            focus:border-0 dark:text-gray-dark-800 placeholder:text-inherit border-0 border-transparent box-border"
                placeholder="Type description here"
                value={textarea1}
                onChange={(e) => setTextarea1(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="lg:overflow-x-hidden basis-6/12 sm:px-3 pt-5 pb-5 gap-4 md:px-6">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Name En
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-12 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="Type name here"
                value={input2}
                onChange={(e) => setInput2(e.target.value)}
              />
            </div>
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Group Name
            </p>
            <div className="rounded-lg border border-neutral dark:border-dark-neutral-border p-[13px]">
              <textarea
                className="textarea w-full p-0 text-gray-800 focus:outline-none resize-none rounded-none bg-transparent min-h-[140px] 
            focus:border-0 dark:text-gray-dark-800 placeholder:text-inherit border-0 border-transparent box-border"
                placeholder="Type description here"
                value={textarea2}
                onChange={(e) => setTextarea2(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center bg-neutral-bg border-neutral dark:bg-dark-neutral-bg rounded-2xl sm:px-3 pt-5 pb-5 gap-[65px] md:px-6">
          <div className="input-group w-full lg:w-6/12 mb-4 lg:mb-0">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-2 dark:text-gray-dark-1100">
              {t("category")}
            </p>
            <select
              className="select w-full lg:min-w-[252px] border rounded-lg font-normal text-sm leading-4 text-gray-400 py-4 h-fit border-[#E8EDF2] dark:border-[#313442] focus:outline-none pl-3 dark:text-gray-dark-400 bg-white dark:bg-[#1F2128]"
              value={dropdown1}
              onChange={(e) => setDropdown1(e.target.value)}
            >
              <option disabled defaultValue="">
                {t("typeCategory")}
              </option>
              <option>{t("home")}</option>
              <option>{t("marge")}</option>
              <option>{t("bart")}</option>
            </select>
          </div>

          <div className="input-group w-full lg:w-6/12 mb-4 lg:mb-0">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-2 dark:text-gray-dark-1100 invisible lg:visible">
              &nbsp;
            </p>
            <select
              className="select w-full lg:min-w-[252px] border rounded-lg font-normal text-sm leading-4 text-gray-400 py-4 h-fit border-[#E8EDF2] dark:border-[#313442] focus:outline-none pl-3 dark:text-gray-dark-400 bg-white dark:bg-[#1F2128]"
              value={dropdown2}
              onChange={(e) => setDropdown2(e.target.value)}
            >
              <option disabled defaultValue="">
                {t("typeCategory")}
              </option>
              <option>{t("home")}</option>
              <option>{t("marge")}</option>
              <option>{t("bart")}</option>
            </select>
          </div>
        </div>

        <div className="md:px-6">
          {" "}
          <button
            className="mt-2 md:mt-0  py-[13px] px-6 bg-[#0077b6] rounded text-white text-xs md:text-sm"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>

      <Popeline />
      <Output />
      <Permissions />
      <Uploads />
    </LayoutWithSidebar>
  );
};

export default EditExperiment;
