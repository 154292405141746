import React from "react";
import Layout from "../../layout/layout";
import { useTranslation } from "react-i18next";
import logo from "../../img/logo/logo.png";
import LinkText from "../../components/link/link-text";
import UpdatePassword from "../../components/form/edit-password";

const UpdatePasswordPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="rounded-2xl bg-white mx-auto p-10 text-center min-w-[440px] max-w-[440px] my-[84px] dark:bg-[#1F2128]">
        <div className="mb-4 text-center mx-auto">
          <img
            src={logo}
            alt="logo"
            className="rounded-[50%] w-[60px] sm:w-[80px] lg:w-[80px] xl:w-[100px] mx-auto"
          />
        </div>
        <h3 className="mb-6 font-bold text-2xl text-gray-1100 capitalize dark:text-gray-dark-1100">
          {t("resetPasswordTitle")}
        </h3>

        <UpdatePassword className="flex gap-6 flex-col" />

        <p className="text-sm text-gray-500 mt-2">
          {t("backToLogin")}{" "}
          <LinkText href="/login" isPlain={false}>
            {t("login")}
          </LinkText>
        </p>
      </div>
    </Layout>
  );
};

export default UpdatePasswordPage;
