import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiExpandVertical, BiCollapseVertical } from "react-icons/bi";
import { TABLE_SHAPE } from "./shapes";
import TdAction, { ACTION_PLACEMENTS } from "./td-action";
import TdContent from "./td-content";

const Table = ({
  columns = [],
  data = [],
  rowActions = [],
  className = "",
  emptyDataMessage,
  ExpandedRowComponent = null,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const { t } = useTranslation();

  const hasExpandedRow = ExpandedRowComponent !== null;

  const hasActions = rowActions.length > 0;

  const isDataEmpty = data.length === 0;
  const dataLen = data.length;

  const getActionPlacement = (trIdx) => {
    return trIdx + 1 === dataLen
      ? ACTION_PLACEMENTS.TOP
      : ACTION_PLACEMENTS.BOTTOM;
  };

  return (
    <div className="w-full overflow-auto">
      <table className={`w-full min-w-[600px] min-h-[100px] ${className}`}>
        <thead>
          <tr>
            {columns.map(({ labelKey, name, className }, thIdx) => (
              <th
                key={`${name}-${thIdx}`}
                className={`text-gray-400 leading-4 font-normal text-left py-3 dark:text-gray-dark-400 text-m pr-4 ${className}`}
              >
                {t(labelKey)}
              </th>
            ))}
            {hasActions && <th className="w-1/12" />}
          </tr>
        </thead>

        <tbody>
          {data.map((row, trIdx) => (
            <React.Fragment key={trIdx}>
              <tr
                key={`row-${trIdx}`}
                className={`border-t border-neutral-200 dark:border-dark-neutral-border ${hasExpandedRow ? "cursor-pointer select-none" : ""}`}
                onClick={() =>
                  setExpandedRow(expandedRow === trIdx ? null : trIdx)
                }
              >
                {columns.map(({ name, helperKey, className, type }, tdIdx) => (
                  <td
                    key={`${name}-${tdIdx}`}
                    className={`py-4 dark:text-dark-neutral-400 text-left pr-4 ${className}`}
                  >
                    <div className={"flex items-center"}>
                      {hasExpandedRow && tdIdx === 0 && (
                        <div className="block p-2">
                          {expandedRow === trIdx ? (
                            <BiCollapseVertical />
                          ) : (
                            <BiExpandVertical />
                          )}
                        </div>
                      )}

                      <TdContent
                        row={row}
                        name={name}
                        helperKey={helperKey}
                        type={type}
                      />
                    </div>
                  </td>
                ))}
                {hasActions && (
                  <td
                    key={`actions-${trIdx}`}
                    className="py-4 w-1/12 dark:text-dark-neutral-400 text-right"
                  >
                    <TdAction
                      actions={rowActions}
                      row={row}
                      placement={getActionPlacement(trIdx)}
                    />
                  </td>
                )}
              </tr>
              {hasExpandedRow && expandedRow === trIdx && (
                <tr>
                  <td colSpan="100%">
                    <ExpandedRowComponent row={row} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}

          {isDataEmpty && (
            <tr className="dark:border-dark-neutral-border border-t">
              <td
                className={"p-8 text-neutral-400 text-center"}
                colSpan={columns.length}
              >
                {emptyDataMessage || t("noData")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = TABLE_SHAPE;

export default Table;
