import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputPassword from "../../components/form/input-password";
import Submit from "../../components/form/submit";
import { getIsPasswordInValid } from "../../pages/auth/constants";

const UpdatePassword = ({ className, rowClassName, disabled }) => {
  const { t } = useTranslation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const isPasswordInValid = getIsPasswordInValid(password, confirmPassword);

  const isFormValid =
    !isPasswordInValid && [password, confirmPassword].every((el) => el !== "");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUpdating(true);
    console.log("Данні форми:", password, confirmPassword);
  };

  return (
    <>
      <form className={className} action="" method="" onSubmit={handleSubmit}>
        <div className={rowClassName}>
          <InputPassword
            label={t("password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            required
            disabled={disabled}
          />
        </div>
        <div className={rowClassName}>
          <InputPassword
            label={t("confirmPassword")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            id="confirmPassword"
            required
            disabled={disabled}
          />
        </div>
        <div className={rowClassName}>
          <Submit
            label={
              isUpdating ? t("updatingPasswordBtn") : t("updatePasswordBtn")
            }
            disabled={isUpdating || !isFormValid}
          />
        </div>
      </form>

      <div className="text-red text-sm">
        {isPasswordInValid && (
          <p className="mt-4">{t("passwordInvalidWithConfirm")}</p>
        )}
      </div>
    </>
  );
};

UpdatePassword.propTypes = {
  className: PropTypes.string,
  rowClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default UpdatePassword;
