import React, { useState } from "react";
import PropTypes from "prop-types";
import InputIcon, { INPUT_ICONS } from "./input-icon";
import {
  MAX_LENGTH_PASSWORD,
  MIN_LENGTH_PASSWORD,
} from "../../pages/auth/constants";

const InputPassword = ({
  label = "",
  disabled = false,
  value = "",
  onChange = () => {},
  id,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const fieldType = showPassword ? "text" : "password";
  const icon = showPassword ? INPUT_ICONS.PASSWORD_TEXT : INPUT_ICONS.PASSWORD;

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
    e.preventDefault();
  };

  return (
    <label className="w-full flex flex-col gap-4">
      {!!label && (
        <span className="text-gray-1100 text-base leading-4 font-medium dark:text-gray-dark-1100">
          {label}
          {" *"}
        </span>
      )}
      <div
        className={`h-fit w-full min-h-fit flex items-center
                border border-neutral dark:border-gray-800 rounded-lg
                bg-transparent focus:outline-none disabled:bg-transparent disabled:cursor-not-allowed
                placeholder:text-inherit`}
      >
        <input
          id={id}
          name={id}
          className={`h-fit w-full min-h-fit p-4
                border-0 bg-transparent focus:outline-none disabled:bg-transparent disabled:cursor-not-allowed
                leading-4 text-sm text-gray-800 dark:text-gray-dark-800 ${disabled ? "text-gray-400" : ""}
                placeholder:text-inherit`}
          type={fieldType}
          disabled={disabled}
          value={value}
          onChange={onChange}
          required
          minLength={MIN_LENGTH_PASSWORD}
          maxLength={MAX_LENGTH_PASSWORD}
        />
        <button className="px-3 flex-grow-0" onClick={handleShowPassword}>
          <InputIcon icon={icon} />
        </button>
      </div>
    </label>
  );
};

InputPassword.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default InputPassword;
