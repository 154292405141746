import biology from "../../img/categories/biology.png";
import geocosm from "../../img/categories/geocosm.png";
import geology from "../../img/categories/geology.png";
import geometeoroly from "../../img/categories/geometeoroly.png";
import medicine_physiology from "../../img/categories/medicine_physiology.png";
import oceanography from "../../img/categories/oceanography.png";

export const CATEGORIES = {
  // Геологія та геофізика,
  GEOLOGY_AND_GEOPHYSICS: "geology-and-geophysics",
  // Геокосмос,
  GEOCOSMOS: "geo-cosmos",
  // Гідрометеорологія,
  HYDROMETEOROLOGY: "hydrometeorology",
  // Океанографія,
  OCEANOGRAPHY: "oceanography",
  // Біологія,
  BIOLOGY: "biology",
  // Медицина та фізіологія
  MEDICINE_AND_PHYSIOLOGY: "medicine-and-physiology",
};

export const CATEGORIES_CONFIG = {
  [CATEGORIES.GEOLOGY_AND_GEOPHYSICS]: {
    value: CATEGORIES.GEOLOGY_AND_GEOPHYSICS,
    labelKey: "geo",
    img: geology,
    link: "/data/geology-and-geophysics",
    disabled: true,
  },
  [CATEGORIES.GEOCOSMOS]: {
    value: CATEGORIES.GEOCOSMOS,
    labelKey: "geocosmos",
    img: geocosm,
    link: "/data/geo-cosmos",
  },
  [CATEGORIES.HYDROMETEOROLOGY]: {
    value: CATEGORIES.HYDROMETEOROLOGY,
    labelKey: "hydrometeorology",
    img: geometeoroly,
    link: "/data/hydrometeorology",
  },
  [CATEGORIES.OCEANOGRAPHY]: {
    value: CATEGORIES.OCEANOGRAPHY,
    labelKey: "oceanography",
    img: oceanography,
    link: "/data/oceanography",
    disabled: true,
  },
  [CATEGORIES.BIOLOGY]: {
    value: CATEGORIES.BIOLOGY,
    labelKey: "biology",
    img: biology,
    link: "/data/biology",
    disabled: true,
  },
  [CATEGORIES.MEDICINE_AND_PHYSIOLOGY]: {
    value: CATEGORIES.MEDICINE_AND_PHYSIOLOGY,
    labelKey: "medicine_physiology",
    img: medicine_physiology,
    link: "/data/medicine-and-physiology",
    disabled: true,
  },
};

export const SUB_CATEGORIES = {
  OZONE: "ozone",
};

export const SUB_CATEGORIES_CONFIG = {
  [SUB_CATEGORIES.OZONE]: {
    value: SUB_CATEGORIES.OZONE,
    labelKey: "ozone",
    parent: CATEGORIES.HYDROMETEOROLOGY,
  },
};
