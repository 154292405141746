import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Category = ({ disabled, img, link, key, labelKey }) => {
  const { t } = useTranslation();

  if (disabled) {
    return (
      <div className="flex flex-col gap-4 items-center justify-center rounded-lg shadow-lg p-6 bg-white opacity-50">
        <div className="w-36 h-36 flex items-center justify-center rounded-full">
          <img src={img} alt="" />
        </div>
        <span className="text-color-brands">{t(labelKey)}</span>
      </div>
    );
  }

  return (
    <Link to={link}>
      <div className="flex flex-col gap-4 items-center justify-center rounded-lg shadow-lg p-6 bg-white hover:shadow-md">
        <div className="w-36 h-36 flex items-center justify-center rounded-full">
          <img src={img} alt="" />
        </div>
        <span className="text-color-brands">{t(labelKey)}</span>
      </div>
    </Link>
  );
};

export default Category;
