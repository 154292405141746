import React, { useEffect, useState } from "react";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import Dropdown from "./dropdown";
import logo from "../../img/logo/logo.png";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
const permissions = [
  {
    pipelineOutput: "Pipeline 1111: raw data",
    group: "Group Name",
    permission: "All",
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1: raw data",
    group: "Group Name 1",
    permission: "Read",
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1: raw data",
    group: "Group Name 2",
    permission: "All",
    id: uuidv4(),
  },
];
const FormGroup = () => {
  const [selectedGender, setSelectedGender] = useState("");
  const { t } = useTranslation();
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
    console.log(selectedGender);
  };

  const getUsersFromLocalStorage = () => {
    const savedUsers = localStorage.getItem("permissionsKey");
    return savedUsers ? JSON.parse(savedUsers) : permissions;
  };
  const saveUsersToLocalStorage = (permissionsArr) => {
    localStorage.setItem("permissionsKey", JSON.stringify(permissionsArr));
  };
  const [permissionsArr] = useState(getUsersFromLocalStorage);

  useEffect(() => {
    saveUsersToLocalStorage(permissionsArr);
  }, [permissionsArr]);

  return (
    <LayoutWithSidebar>
      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden ">
        <div className="flex justify-between gap-6 mb-6 flex-col xl:flex-row">
          <div className=" px-6 scrollbar-hide  lg:overflow-x-hidden basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              First name
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-2 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="John"
              />
            </div>
          </div>

          <div className="flex flex-col items-start gap-4 pr-4 pl-4 basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-2 dark:text-gray-dark-1100">
              Gender
            </p>
            <div className="flex gap-4 flex-wrap sm:flex-row sm:gap-4">
              <label className="flex items-center bg-white border border-gray-400 rounded-full p-2 border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg ">
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={selectedGender === "Male"}
                  onChange={handleGenderChange}
                  className="appearance-none bg-white border border-gray-400 rounded-full w-5 h-5 checked:bg-[#0077b6]  focus:outline-none"
                />
                <span className="ml-2">Male</span>
              </label>
              <label className="flex items-center bg-white border border-gray-400 rounded-full p-2 border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg ">
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={selectedGender === "Female"}
                  onChange={handleGenderChange}
                  className="appearance-none bg-white border border-gray-400 rounded-full w-5 h-5 checked:bg-[#0077b6]  focus:outline-none"
                />
                <span className="ml-2">Female</span>
              </label>
              <label className="flex items-center bg-white border border-gray-400 rounded-full p-2 border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg ">
                <input
                  type="radio"
                  name="gender"
                  value="Other"
                  checked={selectedGender === "Other"}
                  onChange={handleGenderChange}
                  className="appearance-none bg-white border border-gray-400 rounded-full w-5 h-5 checked:bg-[#0077b6] focus:outline-none"
                />
                <span className="ml-2">Other</span>
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-6 mb-6 flex-col xl:flex-row">
          <div className=" px-6 scrollbar-hide lg:overflow-x-hidden basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Last name
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-2 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="Dou"
              />
            </div>
          </div>
          <div className=" px-6 scrollbar-hide lg:overflow-x-hidden basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Country
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-2 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="Ukraine"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-6 mb-6 flex-col xl:flex-row">
          <div className=" px-6 scrollbar-hide  lg:overflow-x-hidden basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Email address
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-2 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="stevenjohn@gmail.com"
              />
            </div>
          </div>
          <div className=" px-6 scrollbar-hide lg:overflow-x-hidden basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Phone *
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-2 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="+ 88 95345 21253"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-6 mb-6 flex-col xl:flex-row">
          <div className=" px-6 scrollbar-hide  lg:overflow-x-hidden basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Organization
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-2 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="NASC"
              />
            </div>
          </div>
          <div className=" px-6 scrollbar-hide lg:overflow-x-hidden basis-6/12">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              Position
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-2 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0 border-0"
                type="text"
                placeholder="Research Officer"
              />
            </div>
          </div>
        </div>
        <div className="px-6 ">
          {" "}
          <button className="mt-2 md:mt-0  py-2 px-6 bg-[#0077b6] rounded text-white text-xs md:text-sm mr-1">
            Save
          </button>
          <button className="mt-2 md:mt-0  py-2 px-6 bg-[#7e7e8f] rounded text-white text-xs md:text-sm mr-1">
            Cancel
          </button>
          <button className="mt-2 md:mt-0  py-2 px-6 bg-[#EC8C56] rounded text-white text-xs md:text-sm mr-1">
            Verify email
          </button>
          <button className="mt-2 md:mt-0  py-2 px-6 bg-[#50D1B2] rounded text-white text-xs md:text-sm mr-1">
            Email verified
          </button>
        </div>
      </div>

      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden w-full mt-4">
        <div className="w-full">
          <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
            {t("groups")}
          </h2>
          <table className="w-full">
            <tr className="dark:border-dark-neutral-border rounded-[25px] flex items-center">
              <td className="flex items-center">
                <img
                  src={logo}
                  alt=""
                  className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                />
                <div className="flex items-center gap-[5px]">
                  <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                    Ozon 24 Data
                  </span>
                </div>
                <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                  <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                    12 weekends
                  </span>
                </td>
              </td>

              <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-borde ml-auto">
                <Dropdown />
              </td>
            </tr>
            <tr className="dark:border-dark-neutral-border border-t  flex items-center  ">
              <td className="flex items-center">
                <img
                  src={logo}
                  alt=""
                  className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                />
                <div className="flex items-center gap-[5px]">
                  <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                    Ozon 24 Data
                  </span>
                </div>
              </td>
              <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                  12 weekends
                </span>
              </td>

              <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border ml-auto">
                <Dropdown />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden mt-3 px-6  pt-[20px] pb-[20px] mb-6 ">
        <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100 px-6  pt-[20px] pb-[10px]">
          Pipeline outputs: permissions
        </p>
        <section className="flex flex-col">
          <div className="flex justify-between gap-6 mb-6 flex-col w-full">
            <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden w-full">
              <div className="overflow-x-scroll ">
                <table className="w-full">
                  <thead>
                    <tr className="">
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        Pipeline output
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        Group
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        Access
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                        Permission
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissionsArr.map((permission) => (
                      <tr
                        className="dark:border-dark-neutral-border border-t rounded-[25px]"
                        key={permission.id}
                      >
                        <td className="flex">
                          <img
                            src={logo}
                            alt=""
                            className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                          />
                          <div className="flex items-center gap-[5px]">
                            <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px] whitespace-nowrap">
                              {permission.pipelineOutput}
                            </span>
                          </div>
                        </td>
                        <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                          <span className="bg-neutral text-gray-500 leading-3 rounded dark:bg-dark-neutral-border py-[10px] px-[18px] dark:text-gray-dark-500 text-[15px] mr-6 whitespace-nowrap">
                            {permission.group}
                          </span>
                        </td>
                        <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                          <span className="bg-neutral text-gray-500 leading-3 rounded dark:bg-dark-neutral-border py-[10px] px-[18px] dark:text-gray-dark-500 text-[15px] mr-6 ">
                            {permission.permission}
                          </span>
                        </td>
                        <td className="">
                          <Dropdown />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </LayoutWithSidebar>
  );
};

export default FormGroup;
