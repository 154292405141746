import React from "react";
import PropTypes from "prop-types";

const Submit = ({ label = "", disabled = false, onClick = () => {} }) => {
  return (
    <button
      className="w-full h-[50px] py-0 px-4
                border-0 bg-color-brands rounded-lg
                text-white dark:text-white text-base font-medium
                focus:outline-none disabled:opacity-30 disabled:cursor-not-allowed"
      disabled={disabled}
      onClick={onClick}
      type="submit"
    >
      {label}
    </button>
  );
};

Submit.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default Submit;
