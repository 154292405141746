import React, { useRef, useState } from "react";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import { TbHome } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { CiImageOn } from "react-icons/ci";
import logo from "../../img/logo/logo.png";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
const ProductDetails = () => {
  const [setSelectedFile] = useState(null);
  const { t } = useTranslation();

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log("Selected file:", file);
    }
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <LayoutWithSidebar>
      <div>
        <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
          {t("product details")}
        </h2>
        <div className="flex justify-between flex-col gap-y-2 sm:flex-row mb-[31px]">
          <div className="flex items-center text-xs gap-x-[11px]">
            <a className="flex items-center gap-x-1" href="/">
              <TbHome />
              <span className="capitalize text-gray-500 dark:text-gray-dark-500">
                {t("home")}
              </span>
            </a>
            <IoIosArrowForward />
            <span className="capitalize text-color-brands">
              {t("product details")}
            </span>
          </div>
          <div className="items-center gap-x-2 hidden">
            <time className="text-xs text-gray-500 dark:text-gray-dark-500">
              false
            </time>
          </div>
        </div>
        <div className="flex gap-x-[50px] border rounded-2xl justify-between flex-col gap-y-12 bg-white border-neutral pt-[50px] pb-[132px] px-[29px] dark:border-dark-neutral-border lg:flex-row lg:gap-y-0 dark:bg-[#1F2128]">
          <div className="lg:max-w-[610px] flex-grow">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              {t("product name")}
            </p>
            <div className="input-group border rounded-lg border-[#E8EDF2] dark:border-[#313442] mb-12 focus:outline-none">
              <input
                className="input bg-transparent text-sm leading-4 focus:outline-none w-full  text-gray-800 h-fit min-h-fit py-4 pl-[13px] dark:text-gray-dark-800 placeholder:text-inherit focus:border-0"
                type="text"
                placeholder="Type name here"
              />
            </div>
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              {t("description")}
            </p>
            <div className="rounded-lg mb-12 border border-neutral dark:border-dark-neutral-border p-[13px]">
              <textarea
                className="textarea w-full p-0 text-gray-800  focus:outline-none resize-none rounded-none bg-transparent min-h-[140px] focus:border-0
                dark:text-gray-dark-800 placeholder:text-inherit "
                placeholder="Type description here"
              ></textarea>
            </div>
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              {t("category")}
            </p>
            <select className="select w-full border rounded-lg font-normal text-sm leading-4 text-gray-400 py-4 h-fit min-h-fit border-[#E8EDF2] dark:border-[#313442] focus:outline-none pl-[13px] min-w-[252px] dark:text-gray-dark-400 mb-12 bg-white dark:bg-[#1F2128] ">
              <option disabled="" defaultValue="">
                {t("typeCategory")}
              </option>
              <option>{t("home")}</option>
              <option>{t("marge")}</option>
              <option>{t("bart")}</option>
            </select>
          </div>
          <div className="flex-grow">
            <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100">
              {t("productGallery")}
            </p>
            <div
              className="border-dashed border-2 text-center mb-12 border-neutral py-[26px] dark:border-dark-neutral-border cursor-pinter"
              onClick={handleIconClick}
            >
              <CiImageOn className="mx-auto inline-block mb-[15px] sm:w-[40px] lg:w-[60px] xl:w-[80px] sm:h-[40px] lg:h-[60px] xl:h-[80px] cursor-pointer" />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="hidden"
              />
              <p className="text-sm leading-6 text-gray-500 font-normal mb-[5px]">
                {t("dropImg")}
              </p>
              <p className="leading-6 text-gray-400 text-[13px]">
                {t("allowed")}
              </p>
            </div>
            <div className="flex flex-col mb-12 gap-y-[10px]">
              <div className="flex items-center justify-between py-3 border pl-3 pr-3 transition-all duration-300 border-[#E8EDF2] dark:border-[#313442] rounded-[5px] gap-x-[10px] hover:shadow-xl sm:pr-8 lg:pr-3 xl:pr-8">
                <img
                  src={logo}
                  alt=""
                  className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] "
                />
                <div className="flex flex-col flex-1 gap-y-[10px]">
                  <div className="flex items-center justify-between text-[13px]">
                    {" "}
                    <span className="text-gray-1100 text-sm leading-4 dark:text-gray-dark-1100">
                      Product_thumbnail_1.png
                    </span>
                    <span className="text-xs text-gray-1100 dark:text-gray-dark-1100">
                      1%
                    </span>
                  </div>
                  <progress
                    className="progress progress-accent"
                    value="1"
                    max="100"
                  ></progress>
                </div>
                <MdClose />
              </div>
              <div className="flex items-center justify-between py-3 border pl-3 pr-3 transition-all duration-300 border-[#E8EDF2] dark:border-[#313442] rounded-[5px] gap-x-[10px] hover:shadow-xl sm:pr-8 lg:pr-3 xl:pr-8">
                <img
                  src={logo}
                  alt=""
                  className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] "
                />
                <div className="flex flex-col flex-1 gap-y-[10px]">
                  <div className="flex items-center justify-between text-[13px]">
                    {" "}
                    <span className="text-gray-1100 text-sm leading-4 dark:text-gray-dark-1100">
                      Product_thumbnail_1.png
                    </span>
                    <span className="text-xs text-gray-1100 dark:text-gray-dark-1100">
                      1%
                    </span>
                  </div>
                  <progress
                    className="progress progress-accent"
                    value="1"
                    max="100"
                  ></progress>
                </div>
                <MdClose />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default ProductDetails;
