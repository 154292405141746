import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginPage from "./pages/auth/login-page";
import RegistrationPage from "./pages/auth/registration-page";
import ResetPasswordPage from "./pages/auth/reset-password-page";
import SignUpSuccess from "./pages/auth/sign-up-success";
import UpdatePasswordPage from "./pages/auth/update-password-page";

import HomePage from "./pages/home/home-page";

import Account from "./pages/account";
import EditGroup from "./pages/account/edit-group";

import Uploads from "./pages/uploads";
import Explore from "./pages/explore";
import ProductDetails from "./pages/product-details/product-details";
import PeopleGroup from "./pages/examples/people-group";
import EditExperiment from "./pages/examples/edit-experiment";
import FormGroup from "./pages/examples/form-group";
import Charts from "./pages/examples/charts";
import { USER_ROLES } from "./components/groups/user-groups-settings";
import DataPage from "./pages/data/data-page";
import Scale from "./components/charts/scale";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/explore" element={<Explore />} />

        {/* Auth pages: */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/sign-up-success" element={<SignUpSuccess />} />
        <Route path="/request-reset-password" element={<ResetPasswordPage />} />
        <Route path="/update-password" element={<UpdatePasswordPage />} />

        {/* Data pages: */}
        <Route path="/data" element={<DataPage />} />
        <Route path="/data/:category/" element={<DataPage />} />
        <Route path="/data/:category/:id" element={<DataPage />} />

        {/* User management pages : */}
        <Route path="/account" element={<Account />} />

        {/*todo: replace with role based view*/}
        <Route
          path="/account/user"
          element={<Account role={USER_ROLES.USER} />}
        />
        <Route
          path="/account/member"
          element={<Account role={USER_ROLES.MEMBER} />}
        />
        <Route
          path="/account/chief"
          element={<Account role={USER_ROLES.CHIEF} />}
        />
        <Route
          path="/account/admin"
          element={<Account role={USER_ROLES.ADMIN} />}
        />
        <Route
          path="/account/edit-group"
          element={<EditGroup role={USER_ROLES.ADMIN} groupId="abc" />}
        />
        <Route
          path="/account/add-group"
          element={<EditGroup role={USER_ROLES.ADMIN} />}
        />

        {/* Mock pages: */}
        <Route path="/uploads" element={<Uploads />} />
        <Route path="/product" element={<ProductDetails />} />
        <Route path="/charts" element={<Charts />} />
        <Route path="/people/group" element={<PeopleGroup />} />
        <Route path="/edit/group" element={<EditGroup />} />
        <Route path="/edit-experiment" element={<EditExperiment />} />
        <Route path="/form-group" element={<FormGroup />} />
        <Route path="/chart-scale" element={<Scale />} />
      </Routes>
    </Router>
  </React.StrictMode>,
);

reportWebVitals();
