import React, { useState } from "react";
import PropTypes from "prop-types";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import BreadCrumbs from "../../components/breadcrumbs";
import { useTranslation } from "react-i18next";
import {
  DATA_MOCK_DATA_PRESENCE,
  FILES_MOCK,
  FOLDER_RESPONSE_MOCK,
} from "./files-mock";
import Tags from "../../components/tags";
import Scale from "../../components/charts/scale";
import { CATEGORIES, CATEGORIES_CONFIG } from "./categories-settings";
import Table from "../../components/table/table";
import { FILES_COLUMNS } from "./data-columns";
import SingleDataPageExpanded from "./single-data-page-expanded";

const SingleDataPage = ({ category, id }) => {
  const { t } = useTranslation();
  const [activeBarIndex, setActiveBarIndex] = useState();

  const categoryTitle = t(CATEGORIES_CONFIG[category].labelKey) || category;

  // todo: add fetch data from api
  const data = {
    ...FOLDER_RESPONSE_MOCK,
  };

  const filesData = [...FILES_MOCK];

  return (
    <LayoutWithSidebar>
      <div>
        <h1 className="text-gray-1100 font-bold text-lg md:text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
          {data.title}
        </h1>

        <BreadCrumbs
          currentPage={data.title}
          links={[{ name: categoryTitle, link: `/data/${category}` }]}
        />

        <div className="overflow-x-scroll">
          <div className="min-w-[400px]">
            <div className="flex flex-col gap-8">
              <Scale
                data={DATA_MOCK_DATA_PRESENCE}
                name={t("measurementCount")}
                activeIndex={activeBarIndex}
                setActiveIndex={setActiveBarIndex}
              />

              <Tags tags={data.tags} />

              <section
                className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
              >
                <Table
                  data={filesData}
                  columns={FILES_COLUMNS}
                  ExpandedRowComponent={({ row }) => (
                    <SingleDataPageExpanded row={row} />
                  )}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

SingleDataPage.propTypes = {
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
};

export default SingleDataPage;
