import React, { useState } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

const Dropdown = () => {
  const [actionsActive, setActionsActive] = useState(false);
  const toggleActions = () => {
    setActionsActive(!actionsActive);
  };
  return (
    <div className="dropdown dropdown-end" onClick={toggleActions}>
      <button
        className="cursor-pointer dropdown-label flex items-center justify-between p-4"
        tabIndex="0"
        onClick={toggleActions}
      >
        <HiOutlineDotsHorizontal className="z-0" />
      </button>
      {actionsActive && (
        <ul className="dropdown-content relative z-10" tabIndex="0">
          <div className="relative menu rounded-box dropdown-shadow min-w-[126px] bg-neutral-bg mt-[-10px] pt-[14px] pb-[7px] px-4 border border-neutral-border dark:text-gray-dark-500 dark:border-dark-neutral-border dark:bg-dark-neutral-bg">
            <div className="border-solid border-b-8 border-x-transparent border-x-8 border-t-0 absolute w-[14px] top-[-7px] border-b-transparent right-[18px]"></div>
            <li className="text-normal mb-[7px]">
              <a
                className="flex items-center bg-transparent p-0 gap-[7px]"
                href="/"
              >
                {" "}
                <span className="text-gray-500 text-[11px] leading-4 hover:text-gray-700">
                  Approve
                </span>
              </a>
            </li>
            <li className="text-normal mb-[7px]">
              <a
                className="flex items-center bg-transparent p-0 gap-[7px]"
                href="/"
              >
                {" "}
                <span className="text-gray-500 text-[11px] leading-4 hover:text-gray-700">
                  Unapprove
                </span>
              </a>
            </li>
            <li className="text-normal mb-[7px]">
              <a
                className="flex items-center bg-transparent p-0 gap-[7px]"
                href="/"
              >
                {" "}
                <span className="text-gray-500 text-[11px] leading-4 hover:text-gray-700">
                  Mark as spam
                </span>
              </a>
            </li>
            <li className="text-normal mb-[7px]">
              <a
                className="flex items-center bg-transparent p-0 gap-[7px]"
                href="/"
              >
                {" "}
                <span className="text-gray-500 text-[11px] leading-4 hover:text-gray-700">
                  Move to Trash
                </span>
              </a>
            </li>
            <div className="w-full bg-neutral h-[1px] my-[7px] dark:bg-dark-neutral-border"></div>
            <li className="text-normal mb-[7px]">
              <a
                className="flex items-center bg-transparent p-0 gap-[7px]"
                href="#remove"
              >
                {" "}
                <span className="text-red text-[11px] leading-4">Block</span>
              </a>
            </li>
          </div>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
