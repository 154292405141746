import React from "react";
import {
  XAxis,
  YAxis,
  Legend,
  LineChart,
  ResponsiveContainer,
  Line,
  CartesianGrid,
} from "recharts";

const LineChartCustomized = ({
  data,
  minDiff = -10,
  maxDiff = 10,
  value = "value",
  xAxisKey = "data",
  name,
} = {}) => {
  if (!data) {
    return null;
  }

  const minValue = Math.min(...data.map((item) => item[value])) || 0;
  const maxValue = Math.max(...data.map((item) => item[value])) || 1;
  return (
    <div className="w-full h-48">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          // layout="vertical"
          data={data}
          margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#313442" />
          <XAxis dataKey={xAxisKey} />
          <YAxis domain={[minValue + minDiff, maxValue + maxDiff]} />
          <Legend />
          <Line dataKey={value} name={name} stroke="#0077b6" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartCustomized;
