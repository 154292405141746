import PropTypes from "prop-types";

export const COLUMNS_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    // key from translation
    labelKey: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string, // ex: w-3/12
  }),
);

export const DATA_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
).isRequired;

export const TABLE_ROW_ACTION_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    labelKey: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
  }),
);

export const TABLE_SHAPE = {
  columns: COLUMNS_SHAPE,
  data: DATA_SHAPE,
  rowActions: TABLE_ROW_ACTION_SHAPE,
  onRowClick: PropTypes.func,
  className: PropTypes.string,
  emptyDataMessage: PropTypes.string,
  ExpandedRowComponent: PropTypes.func,
};
