import React from "react";
import PropTypes from "prop-types";
import Layout from "../../layout/layout";
import logo from "../../img/logo/logo.png";
const ServicePage = ({ title, text }) => {
  return (
    <Layout>
      <div className="rounded-2xl bg-white mx-auto p-10 text-center max-w-[440px] my-[84px] dark:bg-[#1F2128]">
        <div className="mb-4 text-center mx-auto">
          <img
            src={logo}
            alt="logo"
            className="rounded-[50%] w-[60px] sm:w-[80px] lg:w-[80px] xl:w-[100px] mx-auto"
          />
        </div>
        <h3 className="font-bold text-2xl text-gray-1100 mb-[5px] dark:text-gray-dark-1100">
          {title}
        </h3>
        <p className="text-sm text-gray-500 mb-[30px] dark:text-gray-dark-500">
          {text}
        </p>
      </div>
    </Layout>
  );
};
ServicePage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default ServicePage;
