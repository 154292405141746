import React from "react";
import LayoutWithSidebar from "../../layout/layout-with-sidebar";
import { useTranslation } from "react-i18next";
import { TbHome } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import logo from "../../img/logo/logo.png";
import Dropdown from "./dropdown";
import Uploads from "./uploads";

const PeopleGroup = () => {
  const { t } = useTranslation();
  return (
    <LayoutWithSidebar>
      <div>
        <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
          {t("experiments")}
        </h2>
        <div className="flex justify-between flex-col gap-y-2 sm:flex-row mb-[31px]">
          <div className="flex items-center text-xs gap-x-[11px]">
            <a className="flex items-center gap-x-1" href="/">
              <TbHome />
              <span className="capitalize text-gray-500 dark:text-gray-dark-500">
                {t("home")}
              </span>
            </a>
            <IoIosArrowForward />
            <span className="capitalize text-color-brands">
              {t("experiments")}
            </span>
          </div>
          <div className="items-center gap-x-2 hidden">
            <time className="text-xs text-gray-500 dark:text-gray-dark-500">
              false
            </time>
          </div>
        </div>
        <section className="flex flex-col">
          <div className="flex justify-between gap-6 mb-6 flex-col w-full">
            <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden w-full">
              <div className="overflow-x-scroll ">
                <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
                  {t("experiments")}
                </h2>
                <table className="w-full">
                  <thead>
                    <tr className="">
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        {t("name")}
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        becam public in
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                        files
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                        {t("status")}
                      </th>
                      <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                        {t("actions")}
                      </th>
                    </tr>
                  </thead>
                  <tr className="dark:border-dark-neutral-border border-t rounded-[25px]">
                    <td>
                      <img
                        src={logo}
                        alt=""
                        className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                      />
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <div className="flex items-center gap-[5px]">
                        <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                          Ozon 24 Data
                        </span>
                      </div>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                        12 weekends
                      </span>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                        12 files
                      </span>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                        not started
                      </span>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border ml-auto">
                      <Dropdown />
                    </td>
                  </tr>
                  <tr className="dark:border-dark-neutral-border border-t rounded-[25px] ">
                    <td>
                      <img
                        src={logo}
                        alt=""
                        className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                      />
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <div className="flex items-center gap-[5px]">
                        <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                          Ozon 24 Data
                        </span>
                      </div>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                        12 weekends
                      </span>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                        12 files
                      </span>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                        not started
                      </span>
                    </td>
                    <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border ml-auto">
                      <Dropdown />
                    </td>
                  </tr>
                </table>
                <div className="mt-[10px]">
                  <a
                    href="/edit-experiment"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-[#0077b6] text-white font-semibold rounded-lg hover:bg-[#508fda] focus:outline-none focus:ring-2"
                  >
                    <span className="text-lg">+</span>
                    <span>Add new</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden w-full">
              <div className="w-full">
                <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">
                  {t("groups")}
                </h2>
                <table className="w-full">
                  <tr className="dark:border-dark-neutral-border rounded-[25px] flex items-center">
                    <td className="flex items-center">
                      <img
                        src={logo}
                        alt=""
                        className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                      />
                      <div className="flex items-center gap-[5px]">
                        <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                          Ozon 24 Data
                        </span>
                      </div>
                      <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                        <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                          12 weekends
                        </span>
                      </td>
                    </td>

                    <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-borde ml-auto">
                      <Dropdown />
                    </td>
                  </tr>
                  <tr className="dark:border-dark-neutral-border border-t  flex items-center  ">
                    <td className="flex items-center">
                      <img
                        src={logo}
                        alt=""
                        className="hidden sm:block sm:w-[40px] lg:w-[60px] xl:w-[80px] p-[10px]"
                      />
                      <div className="flex items-center gap-[5px]">
                        <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                          Ozon 24 Data
                        </span>
                      </div>
                    </td>
                    <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                      <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                        12 weekends
                      </span>
                    </td>

                    <td className="border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border ml-auto">
                      <Dropdown />
                    </td>
                  </tr>
                  <div className="mt-[10px]">
                    <a
                      href="/edit/group"
                      className="inline-flex items-center gap-2 px-4 py-2 bg-[#0077b6] text-white font-semibold rounded-lg hover:bg-[#508fda] focus:outline-none focus:ring-2"
                    >
                      <span className="text-lg">+</span>
                      <span>Add new</span>
                    </a>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </section>
        <Uploads />
      </div>
    </LayoutWithSidebar>
  );
};

export default PeopleGroup;
