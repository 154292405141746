import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { USER_ROLES } from "../../components/groups/user-groups-settings";
import HydroMeteorologyPage from "./hydroMeteorologyPage";
import SingleDataPage from "./single-data-page";
import DataCategories from "./data-categories";
import { CATEGORIES } from "./categories-settings";

const DataPage = ({ role = USER_ROLES.USER }) => {
  let { id, category } = useParams();

  const isValidCategory =
    category && Object.values(CATEGORIES).includes(category);

  if (!isValidCategory) {
    return <DataCategories />;
  }

  if (id) {
    return <SingleDataPage category={category} id={id} />;
  }

  switch (category) {
    case CATEGORIES.HYDROMETEOROLOGY:
      return <HydroMeteorologyPage role={role} />;
    default:
      return <HydroMeteorologyPage role={role} />;
  }
};
DataPage.propTypes = {
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
};

export default DataPage;
