import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Table from "../table/table";
import { USER_ROLES } from "./user-groups-settings";
import { USERS_DATA_MOCK } from "./user-groups-data-mock";
import Pagination from "../pagination";
import LinkText from "../link/link-text";
import { USER_ROLE_ACTIONS_LABELS, USERS_COLUMNS } from "./users-columns";

const USERS_PER_PAGE = 10;

const UserList = ({ groupId, role = USER_ROLES.USER }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);

  const showPagination = USERS_DATA_MOCK.length > USERS_PER_PAGE;
  const isAdmin = role === USER_ROLES.ADMIN;

  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  // todo: add fetch data from api
  const userActions = useMemo(
    () => Object.values(USER_ROLE_ACTIONS_LABELS),
    [],
  );

  // todo: add fetch data from api
  const data = USERS_DATA_MOCK;

  return (
    <div className="pb-6">
      <h3 className="capitalize text-gray-1100 font-bold text-lg dark:text-gray-dark-1100 mb-6">
        {t("userListTitle")}
      </h3>

      <Table
        columns={USERS_COLUMNS}
        data={data.slice(
          currentPage * USERS_PER_PAGE,
          (currentPage + 1) * USERS_PER_PAGE,
        )}
        rowActions={userActions}
      />
      {showPagination && (
        <Pagination
          className="mt-[30px] mb-[21px]"
          total={data.length}
          onClick={handlePageChange}
          currentPage={currentPage}
          itemsPerPage={USERS_PER_PAGE}
        />
      )}

      {isAdmin && (
        <div className="mt-8">
          <LinkText
            href="/account/add-group"
            className="inline-flex items-center gap-2 px-4 py-2
              bg-[#0077b6] rounded-lg hover:bg-[#508fda] focus:outline-none focus:ring-2
              text-white font-semibold"
          >
            <span className="text-lg">+</span>
            <span>{t("addNewGroup")}</span>
          </LinkText>
        </div>
      )}
    </div>
  );
};

UserList.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
  groupId: PropTypes.string,
};

export default UserList;
