import React, { useEffect, useState } from "react";
import { MdOutlineWbSunny, MdOutlineShieldMoon } from "react-icons/md";

const THEMES = {
  light: "light",
  dark: "dark",
};

const LOCAL_STORAGE_THEME_KEY = "color-theme";

export function storeTheme(theme) {
  const newTheme =
    theme || localStorage.getItem(LOCAL_STORAGE_THEME_KEY) || THEMES.dark;
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme);
  if (newTheme === THEMES.dark) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
}

const ThemeSwitch = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem(LOCAL_STORAGE_THEME_KEY) || THEMES.dark,
  );
  const isDark = theme === THEMES.dark;

  const switchTheme = () => {
    const newTheme = isDark ? THEMES.light : THEMES.dark;
    setTheme(newTheme);
    storeTheme(newTheme);
  };

  useEffect(() => {
    storeTheme(theme);
  }, [theme]);

  return (
    <button onClick={switchTheme} className="text-2xl">
      {isDark ? (
        <MdOutlineShieldMoon className="mx-auto" />
      ) : (
        <MdOutlineWbSunny className="mx-auto" />
      )}
    </button>
  );
};

export default ThemeSwitch;
