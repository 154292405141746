import React, { useEffect, useState } from "react";
// import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from "uuid";
import logo from "../../img/logo/logo.png";
const outputs = [
  {
    pipelineOutput: "Pipeline 11111: raw data",
    lastUpdated: "3 min ago",
    publicData: "12 weekends",
    becamePublic: "Never",
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 11111: raw data",
    lastUpdated: "3 min ago",
    publicData: "12 weekends",
    becamePublic: "3 months",
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1111: raw data",
    lastUpdated: "3 min ago",
    publicData: "12 weekends",
    becamePublic: "Never",
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1111: raw data",
    lastUpdated: "3 min ago",
    publicData: "12 weekends",
    becamePublic: "3 months",
    id: uuidv4(),
  },
  {
    pipelineOutput: "Pipeline 1: raw data",
    lastUpdated: "3 min ago",
    publicData: "12 weekends",
    becamePublic: "Never",
    id: uuidv4(),
  },
];

const Output = () => {
  // const { t } = useTranslation();

  const getUsersFromLocalStorage = () => {
    const savedUsers = localStorage.getItem("outputsKey");
    return savedUsers ? JSON.parse(savedUsers) : outputs;
  };
  const saveUsersToLocalStorage = (outputsArr) => {
    localStorage.setItem("outputsKey", JSON.stringify(outputsArr));
  };
  const [outputsArr, setOutputs] = useState(getUsersFromLocalStorage);

  useEffect(() => {
    saveUsersToLocalStorage(outputsArr);
  }, [outputsArr]);

  const deleteUser = (id) => {
    setOutputs(outputsArr.filter((output) => output.id !== id));
  };
  return (
    <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden mt-3 px-6  pt-[20px] pb-[20px] mb-6 ">
      <p className="text-gray-1100 text-base leading-4 font-medium capitalize mb-[10px] dark:text-gray-dark-1100 px-6  pt-[20px] pb-[10px]">
        Pipeline outputs: delay | STATUSES
      </p>
      <section className="flex flex-col">
        <div className="flex justify-between gap-6 mb-6 flex-col w-full">
          <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl px-6 scrollbar-hide pt-[25px] pb-[42px] lg:overflow-x-hidden w-full">
            <div className="overflow-x-scroll ">
              <table className="w-full">
                <thead>
                  <tr className="">
                    <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                      Pipeline output
                    </th>
                    <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px] ">
                      Last updated
                    </th>
                    <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                      Has public data
                    </th>
                    <th className="text-gray-400 leading-4 font-normal text-left pb-3 dark:text-gray-dark-400 text-[14px]">
                      Became public
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {outputsArr.map((output) => (
                    <tr
                      className="dark:border-dark-neutral-border border-t rounded-[25px]"
                      key={output.id}
                    >
                      <td className="flex">
                        <img
                          src={logo}
                          alt=""
                          className="hidden sm:block sm:w-[50px] lg:w-[60px] xl:w-[80px] p-[10px] flex-shrink-0 object-contain"
                        />

                        <div className="flex items-center gap-[5px]">
                          <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[59px]">
                            {output.pipelineOutput}
                          </span>
                        </div>
                      </td>
                      <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                        <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                          {output.lastUpdated}
                        </span>
                      </td>
                      <td className="border-t border-neutral pb-[15px] pt-[15px] dark:border-dark-neutral-border">
                        <span className="leading-4 text-gray-1100 text-[14px] dark:text-gray-dark-1100 pr-[91px]">
                          {output.publicData}
                        </span>
                      </td>

                      <td className="">
                        <span className="bg-neutral text-gray-500 leading-3 rounded dark:bg-dark-neutral-border py-[10px] px-[18px] dark:text-gray-dark-500 text-[15px] mr-6 whitespace-nowrap text-xs sm:text-sm md:text-[15px]">
                          {output.becamePublic}
                        </span>
                      </td>
                      <td>
                        <button
                          onClick={() => deleteUser(output.id)}
                          className="py-2 px-6 bg-red rounded text-white text-xs md:text-sm ml-auto"
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="md:mb-2 dark:border-dark-neutral-border border-t pt-5">
                <button className="mt-2 md:mt-0  py-2 px-6 bg-[#0077b6] rounded text-white text-xs md:text-sm">
                  {" "}
                  + Add output
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Output;
